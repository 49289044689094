import { pickBy } from 'lodash'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import { defaultWaxPrice, domainsMap } from '../definitions/defaults.js'
import { itemNameFormatterNoLocale } from '~~/utils/formatters/index.js'
import { bandsByCategory, settingsByCategory } from '~~/utils/sitemapUtils'

export const createTrackObject = function (item, info) {
  const options = Object.assign({}, checkRealProduct(item, info))
  const fields = [
    'product_id',
    'name',
    'category',
    'price',
    'image_url',
    'product_url',
    'product_type',
    'metal_type',
    'design_style',
    'design_id',
    'production_type',
    'stone_color',
    'stone_shape',
    'stone_origin',
    'stone_weight',
    'status',
    'stone_id',
    'setting_id',
    'image2_url',
    'item_qty',
    'qty_price'
  ]
  const payload = {}
  fields.forEach((field) => {
    payload[field] = createValue(item, options, field)
  })

  return pickBy(payload, (value) => !!value)
}

const action = {
  product_id: (item, options) => productIdValue(item, options),
  name: (item, options) => nameValue(item, options),
  category: (item, options) => categoryValue(item, options),
  price: (item, options) => priceValue(item, options),
  image_url: (item, options) => imageUrlValue(item, options),
  product_url: (item, options) => productUrlValue(item, options),
  metal_type: (item, options) => metalTypeValue(item, options),
  design_style: (item, options) => designStyleValue(item, options),
  design_id: (item, options) => designIdValue(item, options),
  product_type: (item, options) => productTypeValue(item, options),
  production_type: (item, options) => productionTypeValue(item, options),
  stone_color: (item, options) => stoneColorValue(item, options),
  stone_shape: (item, options) => stoneShapeValue(item, options),
  stone_origin: (item, options) => stoneOriginValue(item, options),
  stone_weight: (item, options) => stoneWeightValue(item, options),
  status: (item, options) => statusValue(item, options),
  stone_id: (item, options) => stoneIdValue(item, options),
  setting_id: (item, options) => settingIdValue(item, options),
  image2_url: (item, options) => image2UrlValue(item, options),
  item_qty: (item, options) => itemQtyValue(item, options),
  qty_price: (item, options) => qtyPriceValue(item, options)
}

const createValue = function (item, options, field) {
  if (!item) return
  if (!action[field]) return undefined
  return action[field](item, options)
}

const replaceCategory = (value) => {
  const reg = /_(.*)/
  const result = value.match(reg)
  return result[1]
}

const oneWordReplacement = (value) => {
  if (!value) return
  if (value.includes(' ')) {
    return value.split(' ')[1]
  }
  return value
}

const nameValue = (item, info = {}) => {
  return itemNameFormatterNoLocale(item, true)
    .replace('none', '')
    .trim()
    .replace(/\s\s+/g, ' ')
}

const productIdValue = (item, info = {}) => {
  if ([2, 3].includes(item.dataType)) return item.preview.itemRealId
  if ([...bandsByCategory, ...settingsByCategory].includes(item.category))
    return item.id + item.metalTypeCode
  return item.id
}

const itemQtyValue = (item, info = {}) => {
  return info.item_qty
}

const qtyPriceValue = (item, info = {}) => {
  if (!info.item_qty) return undefined
  return info.item_qty * priceValue(item, info)
}

const categoryValue = (item, info = {}) => {
  const arr = []
  if (['DMTO', 'MTO', 'DYO'].includes(info.isProduct)) {
    // DMTO item
    if (item.dataType === 3) {
      arr.push('Designer')
      arr.push(replaceCategory(item.customSetting.category))
      return arr
    }
    if (item.category === 'Custom') {
      arr.push(item.category)
      arr.push(replaceCategory(item.customSetting.category))
      return arr
    }
    arr.push(item.category)
    arr.push(item.customSetting.category.replace('Setting_', ''))
    // arr.push(item.preview.category)
    return arr
  }
  if (['Necklace', 'Pendant'].includes(item.category)) {
    arr.push('Necklace_Pendant')
    return arr
  }
  if (['Setting_Necklace', 'Setting_Pendant'].includes(item.category)) {
    arr.push('Setting_Necklace_Pendant')
    return arr
  }
  arr.push(item.category)
  return arr
}

const priceValue = (item, info = {}) => {
  if (item.category === 'Wax') return priceCents(defaultWaxPrice)
  if (['DMTO', 'MTO', 'DYO'].includes(info.isProduct)) {
    return item.finalPrice.totalPrice
      ? priceCents(item.finalPrice.totalPrice)
      : priceCents(item.finalPrice.originalTotalPrice)
  }
  return item.discountPrice
    ? priceCents(item.discountPrice)
    : priceCents(item.price)
}

const getWaxImageUrl = (item) => {
  return `https://${
    domainsMap[process.env.siteName].prodDomain
  }/images/normal/details/wax-${kebabCase(item.customSetting.category)}.jpg`
}

const imageUrlValue = (item, info = {}) => {
  if (item.category === 'Wax') return getWaxImageUrl(item)
  if (['DMTO', 'MTO'].includes(info.isProduct))
    return item.preview.thumbnails[0].large
  if (info.isProduct === 'DYO') return item.customStone.thumbnails[0].large
  return item.thumbnails[0].large
}

const productUrlValue = (item, info = {}) => {
  return info.product_url
}

const metalTypeValue = (item, info = {}) => {
  if (['DMTO', 'MTO', 'DYO'].includes(info.isProduct)) {
    return item.customSetting.metalName
  }
  return item.metalName || null
}

const designStyleValue = (item, info = {}) => {
  if (['Stone', 'Pair'].includes(item.category)) return null
  if (['Custom'].includes(item.category)) return item.customSetting.styleNames
  if (
    [
      'Setting_Ring',
      'Setting_Earring',
      'Setting_Pendant',
      'Setting_Necklace',
      'Setting_Bracelet'
    ].includes(item.category)
  )
    return item.styleNames
  if (['Wedding Band', 'Plain Band'].includes(item.category))
    return item.styleName
  return item.styles || null
}

const designIdValue = (item, info = {}) => {
  return item.preview?.itemRealId || item.styleId || null
}

const productTypeValue = (item, info = {}) => {
  if (['DMTO', 'MTO'].includes(info.isProduct)) return item.preview.subCategory
  if (info.isProduct === 'DYO') {
    return replaceCategory(item.customSetting.category)
  }
  if (item.category) return oneWordReplacement(item.category)
  return null
}

const productionTypeValue = (item, info = {}) => {
  if (['DMTO', 'MTO', 'DYO'].includes(info.isProduct)) {
    return info.isProduct
  } else {
    return 'Inventory'
  }
}

const stoneParamsReturn = (item, info = {}, field = '') => {
  if (['Pair'].includes(item.category)) return item.stones[0][field]
  if (info.formObject === 'details') {
    if (['Plain Band'].includes(item.category)) return null
    if (
      ['Ring', 'Earring', 'Pendant', 'Necklace', 'Bracelet'].includes(
        item.category
      )
    )
      return item.centerStones[0][field] || null
    if (['Wedding Band'].includes(item.category)) {
      return item.sideStoneOptions.length > 1
        ? item.sideStoneOptions[1][field]
        : item.sideStoneOptions[0][field]
    }
  }
  if (info.formObject === 'review') {
    if (
      [
        'Setting_Ring',
        'Setting_Pendant',
        'Setting_Necklace',
        'Setting_Bracelet'
      ].includes(item.customSetting.category)
    ) {
      return item.customStone[field]
    }
    if (
      item.category === 'Custom' &&
      ['Setting_Earring'].includes(item.customSetting.category)
    ) {
      return item.customStone.stones[0][field]
    }
  }
  return item[field] || null
}

const stoneColorValue = (item, info = {}) => {
  return stoneParamsReturn(item, info, 'color')
}

const stoneShapeValue = (item, info = {}) => {
  return stoneParamsReturn(item, info, 'shape')
}

const stoneOriginValue = (item, info = {}) => {
  return stoneParamsReturn(item, info, 'origin')
}

const stoneWeightValue = (item, info = {}) => {
  if (['Pair'].includes(item.category)) {
    const stone1 = item.stones[0].weight
    const stone2 = item.stones[1].weight
    return +stone1 + +stone2
  }
  if (info.formObject === 'details') {
    if (['Plain Band'].includes(item.category)) return null
    if (
      ['Ring', 'Earring', 'Pendant', 'Necklace', 'Bracelet'].includes(
        item.category
      )
    )
      return item.centerStones[0].totalWeight || null
    if (['Wedding Band'].includes(item.category)) {
      const weight = stonesTotalWeight(item, info)
      if (weight.length > 1) return +weight[1].weight.toFixed(2)
      if (weight.length === 1) return +weight[0].weight.toFixed(2)
    }
  }
  if (info.formObject === 'review') {
    if (
      [
        'Setting_Ring',
        'Setting_Pendant',
        'Setting_Necklace',
        'Setting_Bracelet'
      ].includes(item.customSetting.category)
    ) {
      return item.customStone.weight
    }
    if (
      item.category === 'Custom' &&
      ['Setting_Earring'].includes(item.customSetting.category)
    ) {
      return item.customStone.totalWeight
    }
  }
  return item.weight || null
}

const statusValue = (item, info = {}) => {
  if (['DMTO', 'MTO', 'DYO'].includes(info.isProduct)) {
    const ring = item.customSetting?.itemStatus?.status
    const stone = item.customStone?.itemStatus?.status
    return statusItemList[comparison(ring, stone)]
  }
  return statusItemList[item.itemStatus.status]
}

const stoneIdValue = (item, info = {}) => {
  if (['DMTO', 'MTO', 'DYO'].includes(info.isProduct))
    return item.customStone.id
  // if (['DMTO', 'MTO'].includes(info.isProduct)) {
  //   if (item.category === 'Custom' && item.customStone.id) {
  //     return item.customStone.id
  //   }
  //   return item.preview.stoneId
  // }
  // if (info.isProduct === 'DYO') return item.customStone.id
  return null
}

const settingIdValue = (item, info = {}) => {
  if (['MTO', 'DYO'].includes(info.isProduct))
    return item.customSetting.id + item.customSetting.metalTypeCode
  // if (info.isProduct === 'MTO') {
  //   if (item.category === 'Custom' && item.customSetting.id) {
  //     return item.customSetting.id
  //   }
  //   return item.preview.settingId
  // }
  // if (info.isProduct === 'DYO') return item.customSetting.id
  return null
}

const image2UrlValue = (item, info = {}) => {
  if (item.category === 'Wax') return getWaxImageUrl(item)
  if (info.isProduct === 'DYO') return item.customSetting.thumbnails[0].large
  return null
}

const priceCents = (price) => price * 100

const checkRealProduct = (item, payload) => {
  if (['Custom', 'Wax'].includes(item.category))
    return checkItemReview(item, payload)
  return checkItemDetails(item, payload)
}

const checkItemReview = (item, payload) => {
  let info = {}
  if (item.dataType === 2) {
    info = Object.assign({}, payload)
    info.isProduct = 'MTO'
  } else if (item.dataType === 3) {
    info = Object.assign({}, payload)
    info.isProduct = 'DMTO'
  } else {
    info = Object.assign({}, payload)
    info.isProduct = 'DYO'
  }
  return info
}

const checkItemDetails = (item, payload) => {
  let info = {}
  info = Object.assign({}, payload)
  if (productionTypeList.secondType.includes(item.category)) {
    info.isProduct = 'secondType'
  }
  return info
}

const statusItemList = {
  0: 'Available',
  1: 'Delisted',
  2: 'Out Of Stock',
  3: 'Sold Out',
  4: 'On Hold'
}

const comparison = (num1, num2) => {
  if (num1 === num2) return num1
  return num1 > num2 ? num1 : num2
}

const stonesTotalWeight = (item, options) => {
  return item.sideStoneOptions
    .map((stone, index) => {
      const weight = get(
        options.selectedOptions,
        `sideStoneOptions[${index}].weight.value`,
        0
      )
      return {
        index,
        stoneType: stone.stoneType,
        weight: stone.quantity * weight
      }
    })
    .filter((s) => s.weight)
}

const productionTypeList = {
  firstType: ['Stone', 'Pair', 'Wedding Band', 'Plain Band'],
  secondType: ['Ring', 'Pendant', 'Bracelet'],
  thirdType: ['Earring', 'Necklace']
}
