import get from 'lodash/get'
import tail from 'lodash/tail'
import initial from 'lodash/initial'

import { domainsMap } from '../definitions/defaults.js'

const wpHost = `https://${domainsMap[process.env.siteName].wpDomain}`
const eduHost = `https://${
  domainsMap[process.env.siteName].prodDomain
}/education`

const pageObj = {
  content: '',
  normAssetsLinks,
  normInternalLinks,
  normExternalLinks,
  normVideo360
}

export default {
  page(page) {
    pageObj.content = page.content.rendered
    page.content.rendered = pageObj.content
    page.content.rendered = pageObj
      .normVideo360()
      .normAssetsLinks()
      .normExternalLinks()
      .normInternalLinks().content
    page.metaData = normMetaData(page.yoast_head)
    page.link = normURL(page.link, page.type)
    page.parentLink = normURL(
      get(page, '_embedded.up[0].link', ''),
      get(page, '_embedded.up[0].type', '')
    )
    page.parentTitle = get(page, '_embedded.up[0].title.rendered', '')
    page.description = normDescr(get(page, 'excerpt.rendered', ''))
    return page
  },

  pages(pages) {
    return pages
      .map((page) => {
        page.link = normURL(page.link, page.type)
        page.excerpt.rendered = cutOfVideos360(page.excerpt.rendered)
        return page
      })
      .sort((a, b) => (a.menu_order > b.menu_order ? 1 : -1))
  },

  search(search) {
    return search.map((item) => {
      item.url = normURL(item.url, item.subtype)
      item.descr = normDescr(
        get(item, '_embedded.self[0].excerpt.rendered', '')
      )
      return item
    })
  },

  menu(menu) {
    menu.items.map((item) => {
      item.url = normURL(item.url, item.subtype)
      if (item.children) item.children = menuChildren(item.children)
      return item
    })
    return menu
  },

  menus(menus) {
    return menus
  }
}

function menuChildren(items) {
  return items.map((child) => {
    child.url = normURL(child.url, child.subtype)
    if (child.children) {
      child.children = menuChildren(child.children)
    }
    return child
  })
}

function normMetaData(meta) {
  const normalized = {
    meta: [],
    script: [],
    link: []
  }
  const parsed = trimArray(meta.split('\n')).map((m) => m.trim())
  parsed.forEach((item) => {
    if (item.includes('<meta')) normalized.meta.push(normMeta(item))
    if (item.includes('<script')) normalized.script.push(normScript(item))
    if (item.includes('<link')) normalized.link.push(normLink(item))
  })
  return normalized
}

function trimArray(array) {
  return initial(tail(array))
}

function normMeta(meta) {
  const m = {}
  const cutMeta = meta
    .replace('<meta ', '')
    .replace(/\s\/>$/, '')
    .replace(/>$/, '')
  const regEx = /^([a-z]*)="(.*)"\scontent="(.*)"$/
  const result = regEx.exec(cutMeta)
  if (result) {
    m[result[1]] = result[2]
    m.content = removeAbout(result[3])
  }
  return m
}

function normLink(link) {
  const l = {}
  const cutLink = link
    .replace('<link ', '')
    .replace(/\s\/>$/, '')
    .replace(/>$/, '')
  const regEx = /^([a-z]*)="(.*)"\s([a-z]*)="(.*)"$/
  const result = regEx.exec(cutLink)
  if (result) {
    l[result[1]] = result[2]
    l[result[3]] = removeAbout(result[4])
  }
  return l
}

function normScript(script) {
  const innerHtml = script
    .replace(
      '<script type="application/ld+json" class="yoast-schema-graph">',
      ''
    )
    .replace('</script>', '')
  const parsed = JSON.parse(innerHtml)
  return {
    innerHtml: normStrData(parsed),
    type: 'application/ld+json'
  }
}

function normStrData(innerHtml) {
  return {
    ...innerHtml,
    '@graph': normStrDataArray(innerHtml['@graph'])
  }
}

function normStrDataArray(object) {
  if (Array.isArray(object)) {
    object.forEach((obj) => {
      updateContent(obj)
    })
  } else {
    updateContent(object)
  }
  return object
}

function updateContent(obj) {
  const paramsToUrlUpdate = ['@id', 'url', 'target']
  const paramsToDeepSearch = [
    'potentialAction',
    'breadcrumb',
    'isPartOf',
    'primaryImageOfPage',
    'itemListElement',
    'item'
  ]

  paramsToUrlUpdate.forEach((param) => {
    if (obj[param]) {
      if (getParamString(param, obj).includes('wp-content')) return
      obj[param] = removeAbout(obj[param])
    }
  })
  paramsToDeepSearch.forEach((deep) => {
    if (obj[deep]) {
      obj[deep] = normStrDataArray(obj[deep])
    }
  })
}

// the "target" parameter has changed its type in Yoast plugin. It was string before, now it's an object
function getParamString(param, obj) {
  return obj[param].urlTemplate || obj[param]
}

/**
 * Replace WP domain name with production domain name
 * @param {any} data
 * @returns {string}
 */
function removeAbout(data) {
  if (Array.isArray(data)) {
    return data.map((item) => {
      if (typeof item === 'string') return item.replace(wpHost, eduHost)
      return item
    })
  }
  if (typeof data !== 'string') return data
  if (data.includes('wp-content')) return data
  return data.replace(wpHost, eduHost)
}

function normDescr(descr) {
  if (!descr) return ''
  const norm =
    descr
      .replace(/^<p>|<\/p>$/g, '')
      .replace('&nbsp;', '')
      .replace(/{{[^}]*}}/g, '')
      .replace(/^(.{88}[^\s]*).*/, '$1') // 88 - chars cont to cut
      .trim() + '...'
  return `<p>${norm}</p>`
}

function normVideo360() {
  this.content = this.content
    .replace(/{{\s?/g, '<span class="video-360">')
    .replace(/\s?}}/g, '</span>')
  return this
}

function normAssetsLinks() {
  this.content = this.content.replace(
    /src="\/wp-content/g,
    `src="${wpHost}/wp-content`
  )
  return this
}

function normExternalLinks() {
  // norefferer and nofollow will be cut off by browser if target="_blank" parameter exists
  // nuxt-interpolation module issue
  const regex = /<a\s(?:[^>]*)>/g
  const result = this.content.match(regex)
  if (result) {
    const oldExternalLinks = result.filter((l) => !l.includes(wpHost))
    const newExternalLinks = oldExternalLinks.map((link) => {
      const paramPattern = /[^\d\s]*="[^=]*"/g
      const result = link.match(paramPattern)
      let params = []
      if (result) {
        params = link.match(paramPattern).map((p) => {
          const par = p.split('="')
          if (par[0] === 'rel') {
            par[1] = 'nofollow ' + par[1]
          }
          return par.join('="')
        })
      }
      if (!params.some((p) => p.includes('rel='))) {
        params.push('rel="nofollow"')
      }
      return `<a ${params.join(' ')}>`
    })

    oldExternalLinks.forEach((link, index) => {
      this.content = this.content.replace(link, newExternalLinks[index])
    })
  }
  return this
}

function normInternalLinks() {
  // add "education" to relative links
  const regex = /href="\/(?!education\/)([^/]+)/g
  this.content = this.content.replace(regex, 'href="/education/$1')

  // fix absolute links
  const notAssetsLinkPattern = `(https://about.${
    domainsMap[process.env.siteName].prodDomain
  })(?!/wp-content)`
  const re = new RegExp(notAssetsLinkPattern, 'gm')
  this.content = this.content.replace(re, '/education')
  return this
}

function cutOfVideos360(content) {
  return content.replace(/{{.*}}/gm, '')
}

function normURL(url, subtype = 'page') {
  if (!url) return ''
  let u = url
  if (url.includes(wpHost)) {
    const hash = /#.*$/g.exec(u) ? /#.*$/g.exec(u)[0] : ''
    const query = {}
    if (subtype !== 'page') query.type = subtype
    if (u.includes('?type=')) {
      const type = /\?type=(\w*)/gm.exec(u)[1]
      u = u.replace(/\?type=.*$/g, '')
      if (['page', 'list'].includes(type)) {
        query.type = type
      }
    }

    u = u
      .replace(wpHost, '')
      .replace(/#.*$/g, '')
      .replace(/^\/|\/$/g, '')
      .split('/')
    if (u.length === 2) {
      return {
        name: 'education-index-category-slug',
        params: {
          category: u[0],
          slug: u[1]
        },
        hash,
        query
      }
    }
    return {
      name: 'education-index-category',
      params: {
        category: u[0] || 'home'
      },
      hash,
      query
    }
  }
  u = u.replace(`https://${process.env.prodDomain}`, '').replace(/^\/|\/$/g, '')
  return {
    name: u
  }
}
