import get from 'lodash/get'
import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import omitBy from 'lodash/omitBy'
import {
  defaultGemListQuery,
  defaultJewelryListQuery,
  defaultSettingsQuery,
  defaultWeddingBandsPlainQuery,
  defaultWeddingBandsQuery
} from '~~/utils/definitions/defaults'
import {
  newSortingNamesMap,
  urlToStoreSort
} from '~~/utils/definitions/filters'
import { capitalize } from '~~/utils/formatters'

export default function (itemsType, storeFilters, params, rootGetters) {
  const rootSelectedFilters = omitBy(
    storeFilters.rootSelectedFilters[itemsType],
    isEmpty
  )
  const selectedFilters = {
    ...storeFilters.selectedFilters[itemsType],
    ...rootSelectedFilters
  }

  const serializedFilters = serializationsMap[itemsType](
    itemsType,
    cloneDeep(storeFilters),
    cloneDeep(params),
    cloneDeep(selectedFilters),
    cloneDeep(rootGetters)
  )

  if (params.preset) serializedFilters.preset = params.preset

  return serializedFilters
}

const serializationsMap = {
  stonesList: serializeStoneFilters,
  stonePairsList: serializeStoneFilters,
  braceletsList: serializeJewelryFilters,
  broochesList: serializeJewelryFilters,
  cufflinksList: serializeJewelryFilters,
  earringsList: serializeJewelryFilters,
  necklacesPendantsList: serializeJewelryFilters,
  ringsList: serializeJewelryFilters,
  ringSettings: serializeSettingsFilters,
  pendantSettings: serializeSettingsFilters,
  earringSettings: serializeSettingsFilters,
  braceletSettings: serializeSettingsFilters,
  necklaceSettings: serializeSettingsFilters,
  weddingBands: serializeWeddingBandsFilters,
  weddingBandsPlain: serializeWeddingBandsPlainFilters
}

const filtersNameMap = {
  metalTypes: 'metals',
  priceRange: 'price',
  caratRange: 'weight',
  widthRange: 'width',
  lengthRange: 'length',
  bandWidthRange: 'bandWidth',
  stoneShapes: 'shapes',
  stoneOrigins: 'origins',
  designStyles: 'styles'
}

const filtersNameBandsMap = {
  ...filtersNameMap,
  stoneShapes: 'stoneShapes'
}

function fillQuery(defaultQuery, selectedFilters, filtersNameMap) {
  Object.keys(selectedFilters).forEach((key) => {
    const filterName = filtersNameMap[key] || key
    if (key.includes('Range')) {
      const [min, max] = selectedFilters[key]
      defaultQuery[filterName] = { min: min || null, max: max || null }
      return
    }
    defaultQuery[filterName] = selectedFilters[key]
  })
}

function serializeSettingsFilters(
  itemsType,
  filters,
  params,
  selectedFilters,
  rootGetters
) {
  const prefilter = get(params, 'prefilterQuery', {})

  let query = cloneDeep(defaultSettingsQuery.query)

  if (rootGetters['filters/usePrefilter'])
    query = {
      ...query,
      ...prefilter
    }

  fillQuery(query, selectedFilters, filtersNameMap)

  query.category = itemsType.replace('Settings', '')

  const { sortBy, order } = selectedFilters

  if (sortBy) {
    query.sortBy = newSortingNamesMap[selectedFilters.sortBy[0]]
    query.sortOrder = order[0] === 'asc' ? 1 : 2
  }

  if (params.style) query.styles.push(params.style)

  return query
}

function serializeStoneFilters(
  itemsType,
  filters,
  params,
  selectedFilters,
  rootGetters
) {
  const prefilter = get(params, 'prefilterQuery', {})

  let query = cloneDeep(defaultGemListQuery.query)

  if (rootGetters['filters/usePrefilter'])
    query = {
      ...query,
      ...prefilter
    }

  fillQuery(query, selectedFilters, filtersNameMap)

  const { sortBy, order } = selectedFilters
  if (sortBy) {
    query[urlToStoreSort[sortBy[0]]] = order[0] === 'asc' ? 1 : 2
    query.featuredFirst = 0
  }

  if (params.cuttingstyle)
    query.cuttingStyles.push(capitalize(params.cuttingstyle))

  if (params.isStar) query.isStar = params.isStar

  if (params.webCategory) query.webCategory = params.webCategory

  // If there is no selected filter for stone type then apply main stone type
  // if (!query.stoneTypes.length) {
  //   query.stoneTypes = params.stoneTypes || []
  // }

  query.colorCategories = query.colorCategories?.length
    ? query.colorCategories
    : params.stoneColors?.length
    ? params.stoneColors
    : []
  query.stoneCategories = query.stoneCategories?.length
    ? query.stoneCategories
    : params.stoneTypes?.length
    ? params.stoneTypes
    : []

  return query
}

export function serializeJewelryFilters(
  itemsType,
  filters,
  params,
  selectedFilters,
  rootGetters
) {
  const query = cloneDeep(defaultJewelryListQuery.query)

  query.dataType = filters.dataType[itemsType]

  fillQuery(query, selectedFilters, filtersNameMap)

  const { sortBy, order } = selectedFilters
  if (sortBy) {
    query[urlToStoreSort[sortBy[0]]] = order[0] === 'asc' ? 1 : 2
    query.featuredFirst = 0
  }

  query.isStar = !!params.isStar

  if (params.style) query.styles.push(params.style)

  query.colorCategories = query.colorCategories?.length
    ? query.colorCategories
    : params.stoneColors?.length
    ? params.stoneColors
    : []
  query.stoneCategories = query.stoneCategories?.length
    ? query.stoneCategories
    : params.stoneTypes?.length
    ? params.stoneTypes
    : []

  // query.colorCategories = params.stoneColors || []
  // query.stoneCategories = params.stoneTypes || []

  // query.stoneTypes = params.stoneTypes || []
  // query.colors = params.stoneColors || []

  return query
}

export function serializeWeddingBandsPlainFilters(
  itemsType,
  filters,
  params,
  selectedFilters
) {
  const query = cloneDeep(defaultWeddingBandsPlainQuery.query)

  fillQuery(query, selectedFilters, filtersNameBandsMap)

  const { sortBy, order } = selectedFilters

  if (sortBy) {
    query.sortBy = newSortingNamesMap[selectedFilters.sortBy[0]]
    query.sortOrder = order[0] === 'asc' ? 1 : 2
  }

  return query
}

export function serializeWeddingBandsFilters(
  itemsType,
  filters,
  params,
  selectedFilters
) {
  const query = cloneDeep(defaultWeddingBandsQuery.query)

  fillQuery(query, selectedFilters, filtersNameBandsMap)

  const { sortBy, order } = selectedFilters

  if (sortBy) {
    query.sortBy = newSortingNamesMap[selectedFilters.sortBy[0]]
    query.sortOrder = order[0] === 'asc' ? 1 : 2
  }

  return query
}
