import { defaultWaxPrice } from '~~/utils/definitions/defaults'

export const serializeTaxesAddress = (store, getters) => {
  const street = `${getters.addressForm.shippingStreet1} ${getters.addressForm.shippingStreet2}`
  const city = getters.addressForm.shippingCity
  const zip = getters.addressForm.shippingZipCode
  const state = getters.addressForm.shippingState
  const country = getters.addressForm.shippingCountryId
  const shipping = getters.shipping

  return {
    email: getters.addressForm.email,
    to_country: country,
    to_zip: zip,
    to_state: state,
    to_city: city,
    to_street: street,
    amount: getters.getSubTotal,
    shipping,
    line_items: getLineItems(getters)
  }
}

function getLineItems(getters) {
  const lineItems = []
  const { items, getDocumentsPrice } = getters
  items.forEach((item) => {
    const docsPrice = getDocumentsPrice(item)
    if (docsPrice) {
      lineItems.push({
        id: 'DocFee',
        quantity: 1,
        product_tax_code: '19009',
        unit_price: docsPrice,
        discount: 0
      })
    }
    if (item.extendPlan) {
      lineItems.push({
        id: item.extendPlan.planId,
        quantity: 1,
        product_tax_code: '81111818A0000',
        unit_price: item.extendPlan.price / 100,
        discount: 0
      })
    }

    let price = 0
    let discount = 0
    if (item.category === 'Wax') {
      price = defaultWaxPrice
    } else if (item.category !== 'Custom') {
      price = item.price
      discount = item.discountPrice ? item.price - item.discountPrice : 0
    } else {
      price = item.finalPrice.originalTotalPrice
      discount = item.finalPrice.discountAmt
    }
    lineItems.push({
      id: item.id,
      quantity: 1,
      product_tax_code: '54100000A0000',
      unit_price: price,
      discount
    })
  })

  return lineItems
}
