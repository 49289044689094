import camelCase from 'lodash/camelCase'
import get from 'lodash/get'
import {
  caratFormatter,
  moneyFormatter,
  tcwTFormatter
} from '../formatters/index'
import { getItemId, guid } from '../utils'
import { normalizeSidestoneOptions } from '../normalizers/index'

const toTranslate = [
  'color',
  'shape',
  'prongs',
  'cut',
  'clarity',
  'colorIntensity',
  'origin',
  'enhancement',
  'metalName'
]

export function normalizeSkip(item) {
  item.guid = guid()
  item.fromServer = true
  return item
}

export function normalizeBandsItemsCompare(item) {
  const normalized = {
    ...item,
    guid: item.guid || guid(),
    isDirty: false,
    fromServer: true,
    sideStoneOptions: normalizeSidestoneOptions(item.sideStoneOptions),
    selectedOptions: getBandsSelectedOptions(item),
    totalPrice: item.selectedOptions.settingPrice
  }
  return normalized
}

export function normalizePlainBandsItemsCompare(item) {
  return normalizeBandsItemsCompare(item)
}

export function normalizeCustomItemCompare(item) {
  const customStone = item.finished?.stone || item.finished?.pair
  const customSetting = item.finished?.setting

  const normalized = {
    id: getItemId({ customStone, customSetting }),
    guid: item.guid || guid(),
    isDirty: false,
    serverSync: true,
    fromServer: true,
    category: 'Custom',
    customStone,
    customSetting,
    sideStoneOptions: [],
    selectedOptions: item.selectedOptions,
    finalPrice: item.price,
    preview: item.finished?.previewRequest,
    designer: item.finished?.designer
  }

  normalized.sideStoneOptions = normalizeSidestoneOptions(
    item.finished?.sideStoneOptions
  )
  normalized.selectedOptions = getCustomSelectedOptions(normalized)
  return normalized
}

export default {
  stone(item, params) {
    const compare = {}
    params.forEach((param) => {
      compare[param] = toTranslate.includes(param)
        ? this.$t(`compareList.${param}.${camelCase(item[param])}`)
        : item[param]
    })
    compare.pricePerCt = moneyFormatter(
      item.pricePerCt || item.price / item.weight
    )

    compare.dimensions = dimensions([item])
    item.compare = compare
    return item
  },

  pair(item, params) {
    const compare = {}

    params.forEach((param) => {
      compare[param] = mergeParams.call(this, item.stones, param)
    })

    compare.pricePerCt = moneyFormatter(
      item.price / (item.totalWeight || item.weight)
    )
    compare.weight = mergeParams.call(this, item.stones, 'weight')
    compare.dimensions = dimensions(item.stones)

    item.compare = compare
    return item
  },

  jewelry(item, params) {
    const compare = {}

    params.forEach((param) => {
      compare[param] = mergeParams.call(this, item.centerStones, param)
    })

    compare.metalName = this.$t(
      `compareList.metalName.${camelCase(item.metal.value)}`
    )
    compare.centralStoneWeight = mergeParams.call(
      this,
      item.centerStones,
      'totalWeight',
      ['Bracelet', 'Earring'].includes(item.category)
        ? tcwTFormatter
        : caratFormatter
    )
    compare.centerDimensions = dimensions(item.centerStones)
    compare.sideStonesCount = paramsSumm(item.sideStones, 'qty')
    compare.sideStonesWeight = paramsSumm(
      item.sideStones,
      'totalWeight',
      caratFormatter
    )
    item.compare = compare
    return item
  },

  bands(item, params) {
    const compare = {}

    params.forEach((param) => {
      compare[param] = toTranslate.includes(param)
        ? this.$t(`compareList.${param}.${camelCase(item[param])}`)
        : item[param]
    })

    compare.productionTime = this.$t(
      'compareList.compareTable.productionTimeValue',
      { from: item.productionTimeFrom, to: item.productionTimeTo }
    )

    compare.totalCarat = item.totalCarat ? caratFormatter(item.totalCarat) : ''

    item.compare = compare
    return item
  },

  setting(item, params) {
    const compare = {}

    params.forEach((param) => {
      compare[param] = toTranslate.includes(param)
        ? this.$t(`compareList.${param}.${camelCase(item[param])}`)
        : item[param]
    })

    compare.productionTime = this.$t(
      'compareList.compareTable.productionTimeValue',
      { from: item.productionTimeFrom, to: item.productionTimeTo }
    )

    item.compare = compare
    return item
  },

  custom,

  dmto: custom
}

function custom(item, params) {
  const compare = {
    stone: {},
    setting: {}
  }

  if (item.customStone.category === 'Stone') {
    params.stone.forEach((param) => {
      compare.stone[param] = toTranslate.includes(param)
        ? this.$t(`compareList.${param}.${camelCase(item.customStone[param])}`)
        : item.customStone[param]
    })
    compare.stone.dimensions = dimensions([item.customStone])
    compare.stone.weight = caratFormatter(compare.stone.weight)
  } else {
    params.stone.forEach((param) => {
      compare.stone[param] = mergeParams.call(
        this,
        item.customStone.stones,
        param
      )
      if (param === 'dimensions')
        compare.stone[param] = dimensions(item.customStone.stones)
      if (param === 'weight')
        compare.stone[param] = mergeParams.call(
          this,
          item.customStone.stones,
          param,
          caratFormatter
        )
    })
  }
  compare.stone.pricePerCt = moneyFormatter(item.customStone.pricePerCt)

  params.setting.forEach((param) => {
    compare.setting[param] = getSettingParam.call(this, item, param)
  })

  item.compare = compare
  return item
}

function getBandsSelectedOptions(item) {
  return {
    metalType: {
      key: item.metalTypeCode,
      value: item.metalName
    },
    finish: item.finish.find((i) => i.key === item.selectedOptions.finish),
    ringWidth: item.widths.find((i) => i.key === item.selectedOptions.width),
    ringSize: item.sizes.find((i) => i.key === item.selectedOptions.ringSize),
    sideStoneOptions: getBandsSideStonesOptions(item),
    engraving: item.selectedOptions.engraving
  }
}

function getBandsSideStonesOptions(item) {
  const sideStones = get(item, 'selectedOptions.sideStones', [])
  return sideStones.map((line, index) => {
    return {
      clarity: item.sideStoneOptions[index].clarities.find(
        (c) => c.id === line.clarityId
      ),
      grade: item.sideStoneOptions[index].grades.find(
        (c) => c.id === line.gradeId
      ),
      stoneType: item.sideStoneOptions[index].stoneTypes.find(
        (c) => c.id === line.stoneTypeId
      ),
      position: item.sideStoneOptions[index].position,
      weight: item.sideStoneOptions[index].weights.find(
        (c) => c.value === line.weight // use "value" to search instead of id
      )
    }
  })
}

function getCustomSelectedOptions(item) {
  return {
    metalTypeCode: {
      key: item.customSetting.metalTypeCode,
      value: item.customSetting.metalName
    },
    ringSize: item.customSetting?.sizes?.find(
      (i) => i.key === item.selectedOptions.ringSize
    ),
    sideStoneOptions: getCustomSideStones(item),
    engraving: item.selectedOptions.engraving
  }
}

function getCustomSideStones(item) {
  const sideStones = get(item, 'selectedOptions.sideStones', [])
  return sideStones.map((line, index) => {
    return {
      clarity: item.sideStoneOptions[index].clarities.find(
        (c) => c.id === line.clarityId
      ),
      grade: item.sideStoneOptions[index].grades.find(
        (c) => c.id === line.gradeId
      ),
      stoneType: item.sideStoneOptions[index].stoneTypes.find(
        (c) => c.id === line.stoneTypeId
      ),
      position: item.sideStoneOptions[index].position,
      weight: item.sideStoneOptions[index].weights.find(
        (c) => c.value === line.weight // use "value" to search instead of id
      )
    }
  })
}

function getSettingParam(item, param) {
  let value = item.customSetting[param]
  if (param === 'prong') {
    value = get(item, 'selectedOptions.prong.name', '')
  }

  return toTranslate.includes(param)
    ? this.$t(`compareList.${param}.${camelCase(value)}`)
    : value
}

function mergeParams(array, key, formatter) {
  const string = []
  array.forEach((object) => {
    if (formatter) {
      string.push(formatter.call(this, object[key]))
    } else {
      const value = toTranslate.includes(key)
        ? this.$t(`compareList.${key}.${camelCase(object[key])}`)
        : object[key]
      string.push(value)
    }
  })
  return string.filter((s) => s).join('<br/>')
}

function dimensions(array) {
  const string = []
  array.forEach((object) => {
    string.push(`${object.length}L x ${object.width}W x ${object.height}H`)
  })
  return string.filter((s) => s).join('<br/>')
}

function paramsSumm(array, key, formatter) {
  let total = 0
  array.forEach((object) => {
    total += object[key]
  })
  return formatter ? formatter(total) : total
}
