import cloneDeep from 'lodash/cloneDeep'
import { getCustomItemId } from '~~/utils/sitemapUtils.js'

const normalizeMap = {
  'Wedding Band': weddingBand,
  'Plain Band': plainBand,
  Earring: createEarringObject,
  Setting: createCustomObject,
  Pendant: createJewelryObject,
  Necklace: createJewelryObject,
  Bracelet: createJewelryObject,
  Wax: createWaxObject
}

let baseObj = null
export function normalizeOrder(data) {
  baseObj = cloneDeep(data)
  const response = cloneDeep(data)
  response.bands = response.bands?.map((item) => {
    return normalizeMap[item.category]
      ? normalizeMap[item.category](item)
      : item
  })
  // normalize the settings - create a custom object
  response.custom = response.settings.map((item) => {
    return normalizeMap.Setting(item)
  })
  response.jewelries = response.jewelries.map((item) => {
    return normalizeMap[item.category]
      ? normalizeMap[item.category](item)
      : checkedCategoryJewelries(item)
  })
  response.waxModels = response.waxModels.map((item) => {
    return normalizeMap.Wax(item)
  })
  return removeDuplicateStones(response)
}

function getTotalWithoutWarranty(data) {
  const items = [
    ...data.custom,
    ...data.bands,
    ...data.jewelries,
    ...data.pairs,
    ...data.stones,
    ...data.waxModels
  ]
  const extendPlanItemsSumPrice =
    items.reduce(
      (accumulator, item) => accumulator + item.extendPlan?.price || 0,
      0
    ) / 100
  return data.total - extendPlanItemsSumPrice
}

function createWaxObject(item) {
  const wax = { ...item }
  wax.customSetting.metalTypeCode = item.selectedOptions.metalTypeCode.key
  return { ...wax }
}

function checkedCategoryJewelries(item) {
  if (item.category.includes('Ring')) {
    return createJewelryObject(correctSizeForSimpleRing(item))
  }
  return item
}

function removeDuplicateStones(obj) {
  const cloneObj = cloneDeep(obj)
  if (!cloneObj.settings) return cloneObj
  for (let i = cloneObj.settings.length - 1; i >= 0; i--) {
    const setting = cloneObj.settings[i]
    const stoneArray =
      setting.category === 'Setting_Earring' ? cloneObj.pairs : cloneObj.stones
    const j = stoneArray.findIndex(
      (stone) => stone.id === setting.centerStoneId
    )
    if (j > -1) {
      stoneArray.splice(j, 1)
      cloneObj.settings.splice(i, 1)
    }
  }
  if (cloneObj.settings) cloneObj.settings = []
  cloneObj.totalWithoutWarranty = getTotalWithoutWarranty(cloneObj)
  return cloneObj
}

function createCustomItem(item) {
  const customItem = {}
  customItem.category = item.category === 'Wax' ? 'Wax' : 'Custom'
  customItem.dataType = item.previewRequest?.dataType || null
  customItem.id =
    customItem.dataType === 3
      ? item.previewRequest?.itemRealId
      : getCustomItemId(normalizeObjectForFunc(item))
  customItem.customSetting = cloneDeep(item)
  customItem.designer = customItem.customSetting.designer || null
  customItem.customStone = controlCustomItemFieldCustomStone(baseObj, item)
  customItem.extendPlan = normalizeOrderSummaryExtendPlan(item)
  if (item.category.includes('Earring')) {
    customItem.selectedOptions = normalizeSettingEarringSelectedOptions(item)
  } else {
    customItem.selectedOptions = normalizeSettingRingSelectedOptions(item)
  }
  customItem.finalPrice = getFinalPrice(item)
  if (item.category.includes('Earring')) {
    customItem.sideStoneOptions = []
  } else {
    customItem.sideStoneOptions = normalizeSettingRingSideStoneOptions(item)
  }
  customItem.preview = cloneDeep(item.previewRequest)
  return customItem
}

function normalizeObjectForFunc(item) {
  const cloneObject = cloneDeep(baseObj)
  if (item.category.includes('Earring')) {
    return {
      customStone: cloneObject.pairs.find(
        (stones) => stones.id === item.centerStoneId
      ),
      customSetting: item
    }
  }
  return {
    customStone: cloneObject.stones.find(
      (stone) => stone.id === item.centerStoneId
    ),
    customSetting: item
  }
}

const controlCustomItemFieldCustomStone = (response, item) => {
  const cloneResponse = cloneDeep(response)
  const itemCustom = cloneResponse.settings.find((elem) => {
    return elem.centerStoneId === item.centerStoneId ? elem : null
  })
  if (itemCustom.category.includes('Earring')) {
    const index = cloneResponse.pairs.findIndex(
      (stone) => stone.id === itemCustom.centerStoneId
    )
    return cloneResponse.pairs[index]
  }
  const index = cloneResponse.stones.findIndex(
    (stone) => stone.id === itemCustom.centerStoneId
  )
  return cloneResponse.stones[index]
}

function getFinalPrice(item) {
  const isEarring = item.category.includes('Earring')
  const field = isEarring ? 'pairs' : 'stones'

  const centerStone = baseObj[field].find(
    (stone) => stone.id === item.centerStoneId
  )
  const stoneDiscountPrice = centerStone?.discountPrice ?? 0
  const originalStonePrice = centerStone?.price

  const price = {
    localCurrency: {},
    settingPrice: item.price,
    stonePrice: stoneDiscountPrice,
    originalStonePrice,
    discountAmt: originalStonePrice - stoneDiscountPrice,
    originalTotalPrice: baseObj.totalPrice,
    totalPrice: baseObj.totalDiscountPrice,
    extendReferenceId: item.extendReferenceId
  }

  if (item.localCurrency) {
    price.localCurrency = {
      countryCode: item.localCurrency.countryCode,
      currencyCode: item.localCurrency.currencyCode,
      symbol: item.localCurrency.symbol,
      settingPrice: item.localCurrency.price,
      originalStonePrice: centerStone?.localCurrency?.price,
      stonePrice: centerStone?.localCurrency?.discountPrice ?? 0,
      discountAmt:
        centerStone?.localCurrency?.price -
        (centerStone?.localCurrency?.discountPrice ?? 0),
      originalTotalPrice:
        centerStone?.localCurrency?.price + item.localCurrency.price,
      totalPrice:
        (centerStone?.localCurrency?.discountPrice ?? 0) +
        item.localCurrency.price,
      usdRate: null
    }
  }
  return price
}

function weddingBand(item) {
  const normalized = cloneDeep(item)
  normalized.extendPlan = normalizeOrderSummaryExtendPlan(item)
  normalized.selectedOptions = normalizeBandsSelectedOptions(item)
  return normalized
}

function normalizeBandsSelectedOptions(item) {
  const {
    selectedOptions = {},
    finish = [],
    metalTypes = [],
    sizes = [],
    widths = []
  } = item
  return {
    engraving: selectedOptions?.engraving || '',
    finish:
      finish?.find((el) => el.key === selectedOptions?.finish) || finish?.[0],
    metalType: metalTypes?.find((el) => el.key === item.metalTypeCode),
    ringSize: sizes?.find((el) => el.key === selectedOptions?.ringSize),
    ringWidth: widths?.find((el) => el.key === selectedOptions?.width),
    sideStoneOptions: selectedOptions?.sideStones
      ? normalizeBandsSideStonesOptions(item)
      : undefined
  }
}

function normalizeBandsSideStonesOptions(item) {
  const selected = item.selectedOptions.sideStones
  const options = item.sideStoneOptions
  return selected.map((ss, i) => {
    const item = options[i]
    const position = item.position
    const grade = item.grades.find((g) => g.id === ss.gradeId)
    const stoneType = item.stoneTypes.find((g) => g.id === ss.stoneTypeId)
    const weights = item.weights.filter((w) => w.gradeId === ss.gradeId)
    const weight = weights.find((g) => g.value === ss.weight)
    const clarity = item.clarities.find((c) => c.id === ss.clarityId)
    return { position, grade, weight, clarity, stoneType }
  })
}

function normalizeSettingRingSelectedOptions(item) {
  const engraving = item.selectedOptions.engraving || ''
  const metalTypeCode = item.metalTypes.find(
    (el) => el.key === item.selectedOptions.metalTypeCode.key
  )
  const prong = item.prongs.find((p) => p.id === item.selectedOptions.prong?.id)
  const ringSize = controlFieldRingSize(item)
  const sideStoneOptions = item.selectedOptions.sideStoneOptions
  return { engraving, metalTypeCode, prong, ringSize, sideStoneOptions }
}

const controlFieldRingSize = (item) => {
  if (['Pendant', 'Necklace'].some((cat) => item.category.includes(cat))) {
    return null
  }
  return item.sizes.find(
    (size) => size.key === item.selectedOptions.ringSize.key
  )
}

function normalizeSettingEarringSelectedOptions(item) {
  const engraving = item.selectedOptions.engraving || ''
  const metalTypeCode = item.metalTypes.find(
    (el) => el.key === item.selectedOptions.metalTypeCode.key
  )
  const prong = item.prongs.find((p) => p.id === item.selectedOptions.prong.id)
  return { engraving, metalTypeCode, prong }
}

function plainBand(item) {
  return weddingBand(item)
}

function createEarringObject(item) {
  return weddingBand(item)
}

function createCustomObject(item) {
  const normalized = cloneDeep(item)
  return createCustomItem(normalized)
}

function createJewelryObject(item) {
  const normalized = cloneDeep(item)
  normalized.extendPlan = normalizeJewelryExtendPlan(normalized)
  return normalized
}

function normalizeSettingRingSideStoneOptions(item) {
  if (!item.sideStoneOptions) return null
  if (
    ['Setting_Ring', 'Setting_Pendant', 'Setting_Necklace'].includes(
      item.category
    )
  ) {
    return item.sideStoneOptions
  }
  const selected = item.selectedOptions.sideStoneOptions
  const options = item.sideStoneOptions
  return selected.map((ss, i) => {
    const item = options[i]
    const position = item.position
    const grade = item.grades.find((g) => g.id === ss.grade.id)
    const stoneType = item.stoneTypes.find((g) => g.id === ss.stoneType.id)
    const weights = item.weights.filter((w) => w.gradeId === ss.weight.gradeId)
    const weight = weights.find((g) => g.value === ss.weight.value)
    const clarity = item.clarities.find((c) => c.id === ss.clarity.id)
    return { position, grade, weight, clarity, stoneType }
  })
}

function normalizeOrderSummaryExtendPlan(object) {
  if (!object.selectedOptions.extendPlan) return null
  const clonePlan = cloneDeep(object.selectedOptions.extendPlan)
  clonePlan.price = clonePlan.price * 100
  clonePlan.extendReferenceId = true
  return clonePlan
}

function normalizeJewelryExtendPlan(object) {
  if (!object.extendPlan) return null
  const clonePlan = cloneDeep(object.extendPlan)
  clonePlan.price = clonePlan.price * 100
  clonePlan.extendReferenceId = true
  return clonePlan
}

/*
   men's rings and simple rings ready for shipment - adjusting the object type
*/
const correctSizeForSimpleRing = (item) => {
  if (typeof item.selectedOptions.ringSize === 'object') return item
  const size = item.selectedOptions.ringSize
  item.selectedOptions.ringSize = {
    key: size,
    value: size
  }
  return item
}
