import throttle from 'lodash/throttle'
import { itemNameFormatter } from '../formatters'
import { APP } from '~~/store/modules/app'
import LIST from '~~/store/lists/cart/constants'

/* global Swal */
export default function () {
  this.$store.subscribe((mutation) => {
    makeWishlistSubscriptions.call(this, mutation)
    makeCompareListSubscriptions.call(this, mutation)
    makeCartSubscriptions.call(this, mutation)
    errorSubscription.call(this, mutation)
  })

  const mixin = {
    timer: 3000,
    toast: true,
    timerProgressBar: true,
    position: 'top-end',
    showClass: {
      popup: 'animated fadeIn faster'
    },
    hideClass: {
      popup: 'animated fadeOut'
    },
    customClass: {
      popup: 'notify-popup-class',
      confirmButton: 'notify-confirm-button'
    },
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  }

  const throttledFire = throttle(
    (html, timer = 3000) => {
      this.$sweetalert.insert({ html, timer }, mixin)
    },
    3100,
    { trailing: false }
  )

  function makeCartSubscriptions(mutation) {
    if (mutation.type === `cart/${LIST.ADD_ITEM}`) {
      throttledFire(
        this.$t('notifications.cartItemAdded', [
          itemNameFormatter.call(this, mutation.payload.item, true)
        ])
      )
    }

    if (
      [`cart/${LIST.UPDATE_ITEM}`, `cart/${LIST.REPLACE_ITEM}`].includes(
        mutation.type
      )
    ) {
      const metalTypeCode = mutation.payload.metalTypeCode || ''
      throttledFire(
        this.$t('notifications.cartItemUpdated', [
          itemNameFormatter.call(this, mutation.payload.item, true),
          metalTypeCode
        ])
      )
    }

    if (mutation.type === `cart/${LIST.REMOVE_ITEM}`) {
      const metalTypeCode = mutation.payload.item.metalTypeCode || ''
      throttledFire(
        this.$t('notifications.cartItemRemoved', [
          itemNameFormatter.call(this, mutation.payload.item, true),
          metalTypeCode
        ])
      )
    }

    if (mutation.type === `cart/${LIST.SET_TAX}`) {
      if (mutation.payload?.status === 400) {
        throttledFire(this.$t('notifications.wrongAddress'))
        return
      }
      const state = mutation.payload?.state?.value
      const taxAmount = mutation.payload?.tax?.amount_to_collect
      if (taxAmount) throttledFire(this.$t('notifications.taxAdded', [state]))
    }

    if (mutation.type === `cart/${LIST.REMOVE_TAX}`) {
      throttledFire(this.$t('notifications.taxRemoved'))
    }

    if (mutation.type === `cart/${LIST.BACKEND_TAX_CALC_MODE}`) {
      throttledFire(this.$t('notifications.backendCalc'))
    }
  }

  function makeCompareListSubscriptions(mutation) {
    if (mutation.type === `compareList/${LIST.ADD_ITEM}`) {
      throttledFire(
        this.$t('notifications.compareListItemAdded', [
          itemNameFormatter.call(this, mutation.payload.item, true)
        ])
      )
    }

    if (mutation.type === `compareList/${LIST.REMOVE_ITEM}`) {
      throttledFire(
        this.$t('notifications.compareListRemoved', [
          itemNameFormatter.call(this, mutation.payload.item, true)
        ])
      )
    }

    if ([`compareList/${LIST.UPDATE_ITEM}`].includes(mutation.type)) {
      const metalTypeCode = mutation.payload.item.metalTypeCode || ''
      throttledFire(
        this.$t('notifications.compareListItemUpdated', [
          itemNameFormatter.call(this, mutation.payload.item, true),
          metalTypeCode
        ])
      )
    }
  }

  function makeWishlistSubscriptions(mutation) {
    if (mutation.type === `favorites/${LIST.ADD_ITEM}`) {
      throttledFire(
        this.$t('notifications.wishlistItemAdded', [
          itemNameFormatter.call(this, mutation.payload.item, true)
        ])
      )
    }

    if (mutation.type === `favorites/${LIST.REMOVE_ITEM}`) {
      throttledFire(
        this.$t('notifications.wishlistItemRemoved', [
          itemNameFormatter.call(this, mutation.payload.item, true)
        ])
      )
    }

    if ([`favorites/${LIST.UPDATE_ITEM}`].includes(mutation.type)) {
      throttledFire(
        this.$t('notifications.wishlistItemUpdated', [
          itemNameFormatter.call(this, mutation.payload.item, true)
          // metalTypeCode
        ])
      )
    }
  }

  function errorSubscription(mutation) {
    if (mutation.type === `app/${APP.ERROR}`) {
      throttledFire(mutation.payload, 7000)
    }
  }
}
