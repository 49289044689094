import cloneDeep from 'lodash/cloneDeep'
import { twoDigitsFormatter } from '~~/utils/formatters/index.js'

export const customerData = function (obj) {
  return {
    email: obj.email,
    first_name: obj.firstName,
    last_name: obj.lastName,
    phone: obj.phone,
    address: obj.address,
    city: obj.city,
    state: obj.state,
    postal_code: obj.zip,
    country: obj.country
  }
}

/**
 * Push a fake page view after any contact form is submit successfully (especially preview requests).
 * We send the URL like: /submit-form/form-name.
 * This will be helpful for marketing efforts triggered by preview request submissions
 *
 * @param {HTMLElement|String} form
 * @returns {Object}
 */
export const addPageView = function (form) {
  let url = ''
  if (typeof form === 'string') {
    url = `/submit-form/${form}`
    return { url }
  }
  const name = form.attributes.name?.value || 'unknown'
  url = `/submit-form/${name}`
  return { url }
}

export const addRealPageView = function (route) {
  return { url: window?.location.origin + window?.location.pathname }
}

const defaultSetCustomerData = function (data) {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    gender: data.gender,
    // address1: data.street1,
    // address2: data.street2,
    city: data.city,
    state: data.state,
    // postal_code: data.zipCode,
    country: data.country
  }
}

const personalSetCustomerData = function (data) {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    birthdate: `${twoDigitsFormatter(
      data.birthdayMonth.key
    )}-${twoDigitsFormatter(data.birthdayDay)}`
  }
}

const shippingSetCustomerData = function (data) {
  return {
    first_name: data.shippingFirstName,
    last_name: data.shippingLastName,
    // address1: data.shippingStreet1,
    // address2: data.shippingStreet2,
    city: data.shippingCity,
    state: data.shippingState,
    // postal_code: data.shippingZipCode,
    country: data.shippingCountryId
  }
}

const contactSetCustomerData = function (data) {
  return {
    first_name: data.customerName
  }
}

export const setCartData = function (cartGetters, isOrder = false) {
  const cg = cloneDeep(cartGetters)
  if (!isOrder)
    return {
      cart_value: cg['cart/getTotalWithoutWarranty'] * 100,
      cart_item_count: cg['cart/allListItems'].length,
      return_url: cg['cart/shareUrl']
    }
  return {
    cart_value: cg['cart/orderSummary'].totalWithoutWarranty * 100,
    cart_item_count: cg['cart/orderItems'].length,
    order_number: cg['cart/orderSummary'].id
  }
}

const customerDataObj = {
  defaultSetCustomerData,
  shippingSetCustomerData,
  contactSetCustomerData,
  personalSetCustomerData
}

export const setCustomerData = function ({ form, type = 'default' }) {
  return customerDataObj[`${type}SetCustomerData`](form)
}
