import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'
import invert from 'lodash/invert'
import kebabCase from 'lodash/kebabCase'
import camelCase from 'lodash/camelCase'
import replace from 'lodash/replace'
import { helpers } from 'vuelidate/lib/validators'
import {
  productListTypeByCategory,
  defaultWaxPrice,
  cartRoutes,
  cookiesOpts,
  mensStyleID,
  mensStyleRingID,
  itemsTypeByRouteName,
  defaultCountryCode,
  defaultCountryName
} from './definitions/defaults'
import {
  getColorByDynamicRoute,
  getStoneByDynamicRoute
} from './multiStoneUtils.js'
import { validUrlFilterNames } from './definitions/filters'
import { parametersNamesMap } from './definitions/itemParameters'

export function camelToFlat(camel) {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')

  let flat = ''

  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + ' '
  })
  return flat
}

export const {
  getDetailsPath,
  getItemId,
  normRouteName
} = require('./sitemapUtils')

export function getCountryCode(req) {
  return {
    countryCode:
      req.headers?.['cloudfront-viewer-country'] || defaultCountryCode,
    countryName:
      req.headers?.['cloudfront-viewer-country-name'] || defaultCountryName
  }
}

// Prevent fake analytics tracking from China team and very high volume of orders
export function isFakeOrder(order) {
  if (order.email.includes('@qq.com')) return true
  if (order.total > 100000) return true
  return false
}

/**
 * Returns a validation function that checks if the given value is the same as the
 * value of the reference field, ignoring case.
 *
 * @param {string} referenceField - The name of the reference field.
 * @return {function} A validation function.
 */
export function sameAsLowerCase(referenceField) {
  return helpers.withParams(
    { type: 'sameAs', eq: referenceField },
    function (value, parentVm) {
      const referenceValue = parentVm[referenceField]
      if (typeof value === 'string' && typeof referenceValue === 'string') {
        return value.toLowerCase() === referenceValue.toLowerCase()
      }
      return false
    }
  )
}

export function saveDataToJsonFile(data, fullPath, overwrite = true) {
  const fs = require('fs')
  const exists = fs.existsSync(fullPath)
  if (!overwrite && exists) return

  fs.writeFileSync(
    fullPath,
    JSON.stringify(
      {
        ...data,
        date: Date.now()
      },
      null,
      2
    )
  )
}

export function getDataFromJsonFile(filename = 'data') {
  const fs = require('fs')
  const path = require('path')
  const pathToFile = `projects/${process.env.siteName}/static`
  const dirname = __dirname || path.resolve()
  const fullPath = path.join(dirname, '..', pathToFile, `${filename}.json`)
  const exists = fs.existsSync(fullPath)
  if (!exists) return []
  try {
    const data = fs.readFileSync(fullPath, { encoding: 'utf8' })
    return JSON.parse(data)
  } catch (parseError) {
    console.error('Error JSON parsing:', parseError)
    return null
  }
}

export function getDataType(item) {
  // 1 - DYO, 2 - MTO item, 3 - DMTO item
  if (!item) return null
  if (item.dataType) return item.dataType
  if (!item.previewRequest) return 1
  if (item.previewRequest.dataType) return item.previewRequest.dataType
  return 1
}

export function getHost() {
  const protocol = process.env.isDev ? 'http' : 'https'
  const domain = process.env.isDev
    ? process.env.devDomain
    : process.env.prodDomain
  return `${protocol}://${domain}`
}

export function getExtraParams(params) {
  const preset = getListingItemsPreset(this.$route)

  const subType = getListingItemsSubType(this.$route)
  const isStar = isListingItemsHaveStar(this.$route)
  const origin = getListingItemsOrigin(this.$route)
  const style = getListingItemsStyle(this.$route)
  const webCategory = getListingItemsWebCategory(this.$route)
  const stoneTypes = getListingItemsStoneTypes(this.$route)
  const stoneColors = getListingItemsStoneColors(this.$route)

  if (preset) params.preset = preset

  if (subType) params.cuttingstyle = subType
  if (isStar) params.isStar = isStar
  if (origin) params.origin = origin
  if (style) params.style = style
  if (webCategory) params.webCategory = webCategory
  if (stoneTypes) params.stoneTypes = stoneTypes
  if (stoneColors) params.stoneColors = stoneColors
}

/**
 * Returns preset ID for the given route.
 * Preset ID is an integer used to filter items by preselected filters on backend side.
 * The function checks the route name and returns
 * the corresponding preset ID.
 * Order of conditions is important!
 * @param {Object} route - The route object containing name property.
 * @return {number} The preset ID for the given route.
 */
function getListingItemsPreset(route) {
  if (route.name.includes('blue-green')) return 1
  if (route.name.includes('unique-colored')) return 2
  if (route.name.includes('vintage-sapphire')) return 3
  if (route.name.includes('blue-sapphire-wedding-rings')) return 1
  if (route.name.includes('blue-sapphire-diamond-wedding-rings')) return 2
  if (route.name.includes('pink-sapphire-wedding-rings')) return 3
  if (route.name.includes('pink-sapphire-diamond-wedding-rings')) return 4
  if (route.name.includes('diamond-wedding-rings')) return 5
  if (route.name.includes('sapphire-wedding-rings-bands')) return 6
  return 0
}

export function setPreferableRingSize(cookies, value) {
  cookies.set('ring-size', value, cookiesOpts)
}

export function removeTagsFromString(str) {
  // remove html tags from string
  return str.replace(/<(?:.|\n)*?>/gm, '')
}

export function removeEmptyValuesFromObject(object, extraValues = []) {
  const valuesToOmit = [null, undefined, '', ...extraValues]
  for (const key in object) {
    // eslint-disable-next-line no-prototype-builtins
    if (object.hasOwnProperty(key)) {
      const value = object[key]
      if (valuesToOmit.includes(value)) {
        delete object[key]
      }
    }
  }
}

/**
 * @param route {Object}
 * @returns {boolean}
 */
export function isCartRoute(route) {
  return cartRoutes.includes(normRouteName(route.name))
}

export function getPreferableRingSize(cookies) {
  const size = parseFloat(cookies.get('ring-size'))
  return isNaN(size) ? null : size
}

// get element offset from top of the document
export function getOffset(el) {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function isItemsMatch(item) {
  return !(
    (item.customSetting.category === 'Setting_Earring' &&
      item.customStone.category !== 'Pair') ||
    (item.customSetting.category !== 'Setting_Earring' &&
      item.customStone.category === 'Pair')
  )
}

export function htmlDecode(input) {
  return input.replace(/&#(\d+);/g, function (match, dec) {
    return String.fromCharCode(dec)
  })
}

export function getListingItemsOrigin(route) {
  const name = normRouteName(route.name)
  if (
    ['rubies-burmese-rubies', 'ruby-jewelry-burmese-ruby-rings'].includes(name)
  )
    return 'Burma (Myanmar)'
  if (
    ['rubies-mozambique-rubies', 'ruby-jewelry-mozambique-ruby-rings'].includes(
      name
    )
  )
    return 'Mozambique'
  if (
    [
      'emeralds-colombian-emeralds',
      'emerald-jewelry-colombian-emerald-rings'
    ].includes(name)
  )
    return 'Colombia'
  if (
    [
      'emeralds-zambian-emeralds',
      'emerald-jewelry-zambian-emerald-rings'
    ].includes(name)
  )
    return 'Zambia'
  if (name.includes('montana')) return 'Montana'
  return null
}

export function getListingItemsStoneTypes(route) {
  if (route.fullPath.includes('-gemstones')) return []
  return [getStoneByDynamicRoute(route)?.name].filter(Boolean)
}

export function getListingItemsStoneColors(route) {
  return [getColorByDynamicRoute(route)?.name].filter(Boolean)
}
export function getListingItemsWebCategory(route) {
  const name = normRouteName(route.name)
  if (
    [
      'rubies-gemologist-favorites',
      'emeralds-gemologist-favorites',
      'gemologist-recommended-sapphires',
      'gemologist-recommended-sapphire-pairs'
    ].includes(name)
  )
    return 2

  return 0
}

export function getListingItemsStyle(route) {
  const name = normRouteName(route.name)
  if (
    [
      'mens-gemstone-ring-settings',
      'design-your-own-setting-mens-ring-settings',
      'mens-sapphire-ring-settings'
    ].includes(name)
  )
    return mensStyleID
  if (
    [
      'ruby-jewelry-mens-ruby-rings',
      'emerald-jewelry-mens-emerald-rings',
      'mens-gemstone-rings',
      'mens-sapphire-rings'
    ].includes(name)
  )
    return mensStyleRingID

  return null
}

export function getListingItemsSubType(route) {
  const name = normRouteName(route.name)
  if (
    [
      'rubies-ruby-cabochon',
      'rubies-star-rubies',
      'rubies-matched-pairs-of-rubies-cabochon',
      'rubies-star-ruby-pairs',
      'emeralds-emerald-cabochon',
      'emeralds-matched-pairs-of-emeralds-cabochon',
      'cabochons',
      'star-sapphires',
      'star-sapphire-pairs'
    ].includes(name)
  )
    return 'cabochon'
  if (['sapphire-crystals'].includes(name)) return 'crystal'
  return null
}

export function isListingItemsHaveStar(route) {
  const name = normRouteName(route.name)
  if (
    [
      'ruby-jewelry-star-ruby-rings',
      'rubies-star-rubies',
      'rubies-star-ruby-pairs',
      'star-sapphires',
      'star-sapphire-pairs'
    ].includes(name)
  )
    return 1
  return null
}

export function getLastDetailsPath(params) {
  if (params.isDetails) return params.path?.path || null
  return null
}

export function isTokenValid(tokenExpiration) {
  return tokenExpiration > +new Date()
}

export function isTokenValidTimestamp(tokenExpiration) {
  return tokenExpiration > Date.now()
}

export function normRoute(route) {
  const r = cloneDeep(pick(route, ['name', 'params', 'query']))
  r.name = normRouteName(r.name)
  return r
}

export function getIconName(metal) {
  if (!metal) return 'PLMetal'
  if (metal === 'Palladium') return 'PLMetal'
  if (metal === 'Platinum 950') return 'PTMetal'
  const metalData = metal.split(' ')
  const carat = metalData[0] === '18K' ? 'EighteenK' : 'FourteenK'
  const reverse =
    metalData[1] === 'White' && metalData[3] === 'Yellow' ? '' : 'Reverse'
  return `${carat}${metalData[2] === '&' ? `Double${reverse}` : ''}Metal`
}

export function getIconColor(metal) {
  if (!metal) return '#898989'
  if (['Palladium', 'Platinum 950'].includes(metal)) return '#898989'
  const metalColor = metal.split(' ')
  if (metalColor[1] === 'White') return '#898989'
  if (metalColor[1] === 'Yellow') return '#e4aa09'
  if (metalColor[1] === 'Rose') return '#ff8282'
}

export function getPrice(item) {
  let price

  if (item.category === 'Wax') {
    return defaultWaxPrice
  } else if (item.finalPrice) {
    if (item.customStone.discountPrice) {
      const stonePrice = item.customStone.discountPrice
      const settingPrice = item.finalPrice.settingPrice
      return Number(stonePrice) + Number(settingPrice)
    }
    price = item.finalPrice.totalPrice
  } else {
    if (item.discountPrice) return item.discountPrice
    price = item.price || item.totalPrice
  }

  return price
}

export function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

export function getSettingsPickerPath(type) {
  let defaultPath = { name: 'design-your-own' }
  if (type === 'ringsList')
    defaultPath = {
      name: 'design-your-own-setting-ruby-engagement-ring-settings'
    }
  if (['pendantsList', 'necklacesList'].includes(type))
    defaultPath = {
      name: 'design-your-own-setting-ruby-necklace-pendant-settings'
    }

  if (type === 'earringsList')
    defaultPath = {
      name: 'design-your-own-setting-ruby-earring-settings'
    }
  return defaultPath
}

export class CustomNextPath {
  constructor(data) {
    this.data = {
      customStone: data.customStone,
      customSetting: data.customSetting
    }
    this.customStone = data.customStone
    this.customSetting = data.customSetting
    this.defaultRoute = null
    this.type = data.type
    this.route = data.route
    this.query = data.query || {}
  }

  get() {
    const isSettingEmpty = isEmpty(this.customSetting)
    const isStoneEmpty = isEmpty(this.customStone)

    if (!isStoneEmpty && !isSettingEmpty) return this.getCustomRoute()

    if (isStoneEmpty && isSettingEmpty)
      return {
        name: 'design-your-own'
      }

    if (isStoneEmpty) return this.getStonesRoute()

    if (isSettingEmpty) return this.getSettingsRoute()
  }

  getDefaultRoute() {
    const settings = [
      'ringsList',
      'pendantsList',
      'braceletsList',
      'necklacesList',
      'earringsList'
    ]
    if (this.type && settings.includes(this.type))
      this.defaultRoute = this[`${this.type}DefaultRoute`]()
  }

  getCustomRoute() {
    const { source } = this.route.query
    const path = getDetailsPath(
      {
        ...this.data,
        category: 'Custom'
      },
      this.route
    )
    const route = {
      path,
      query: this.query
    }
    if (source === 'share') route.query.source = 'share'
    return route // all settings are filled
  }

  getStonesRoute() {
    const routeNamesMap = {
      ruby: {
        stones: 'rubies',
        pairs: 'rubies-matched-pairs-of-rubies'
      },
      emerald: {
        stones: 'emeralds',
        pairs: 'emeralds-matched-pairs-of-emeralds'
      },
      gemstone: {
        stones: 'natural-gemstones',
        pairs: 'natural-gemstone-pairs'
      },
      sapphire: {
        stones: 'sapphires',
        pairs: 'sapphire-pairs'
      }
    }
    if (
      productListTypeByCategory[this.customSetting.category] ===
      'earringSettings'
    )
      // for earring setting only pair stones
      return {
        name: routeNamesMap[process.env.siteName].pairs,
        query: { 'design-your-own': 1 }
      }
    return {
      name: routeNamesMap[process.env.siteName].stones,
      query: { 'design-your-own': 1 }
    }
  }

  getSettingsRoute() {
    // detect which setting page should redirect to
    if (
      productListTypeByCategory[this.customStone.category] === 'stonePairsList'
    )
      return this.earringsListDefaultRoute() // for pair stones only earring settings
    this.getDefaultRoute()
    return this.defaultRoute || this.ringsListDefaultRoute() // need to detect which type settings is needed
  }

  ringsListDefaultRoute() {
    const routeNamesMap = {
      ruby: 'design-your-own-setting-ruby-engagement-ring-settings',
      emerald: 'design-your-own-setting-emerald-engagement-ring-settings',
      gemstone: 'gemstone-ring-settings',
      sapphire: 'sapphire-engagement-ring-settings'
    }
    return {
      name: routeNamesMap[process.env.siteName]
    }
  }

  pendantsListDefaultRoute() {
    const routeNamesMap = {
      ruby: 'design-your-own-setting-ruby-necklace-pendant-settings',
      emerald: 'design-your-own-setting-emerald-necklace-pendant-settings',
      gemstone: 'gemstone-necklace-pendant-settings',
      sapphire: 'sapphire-necklace-pendant-settings'
    }
    return {
      name: routeNamesMap[process.env.siteName]
    }
  }

  necklacesListDefaultRoute() {
    const routeNamesMap = {
      ruby: 'design-your-own-setting-ruby-necklace-pendant-settings',
      emerald: 'design-your-own-setting-emerald-necklace-pendant-settings',
      gemstone: 'gemstone-necklace-pendant-settings',
      sapphire: 'sapphire-necklace-pendant-settings'
    }
    return {
      name: routeNamesMap[process.env.siteName]
    }
  }

  earringsListDefaultRoute() {
    const routeNamesMap = {
      ruby: 'design-your-own-setting-ruby-earring-settings',
      emerald: 'design-your-own-setting-emerald-earring-settings',
      gemstone: 'gemstone-earring-settings',
      sapphire: 'sapphire-earring-settings'
    }
    return {
      name: routeNamesMap[process.env.siteName]
    }
  }

  braceletsListDefaultRoute() {
    const routeNamesMap = {
      ruby: 'design-your-own-setting-ruby-bracelet-settings',
      emerald: 'design-your-own-setting-emerald-bracelet-settings',
      gemstone: 'gemstone-bracelet-settings',
      sapphire: 'sapphire-bracelet-settings'
    }
    return {
      name: routeNamesMap[process.env.siteName]
    }
  }
}

export function generateId() {
  return String(Math.round(Math.random() * 10000000000))
}

export function getItemFullId(item) {
  const metalTypeCode = item.metalTypeCode ? item.metalTypeCode : ''
  return `${item.id || ''}${metalTypeCode}`
}

export function getLocalPrice(usdPrice, usdRate, conversionRate) {
  if (!usdRate || !conversionRate) return usdPrice
  return (usdPrice / usdRate) * conversionRate
}

export function getOriginalPrice(localPrice, usdRate, conversionRate) {
  if (!usdRate || !conversionRate) return localPrice
  return (localPrice / conversionRate) * usdRate
}

export function query(store, route) {
  const customStone = store.getters['customItem/customStone']
  const customSetting = store.getters['customItem/customSetting']

  const stoneId = customStone?.id || store.state.serverCookies?.customStone
  const settingId =
    customSetting?.id || store.state.serverCookies?.customSetting

  const routeName = normRouteName(route.name)
  const isStonesPage = ['gemstone', 'sapphire'].includes(process.env.siteName)
    ? isStonesDynamicPage(route)
    : [
        ...itemsTypeByRouteName.stonesList,
        ...itemsTypeByRouteName.stonePairsList
      ].includes(routeName)
  const isSettingsPage = ['gemstone', 'sapphire'].includes(process.env.siteName)
    ? isSettingsDynamicPage(route)
    : /design-your-own-setting/.test(routeName)

  if (settingId && isStonesPage) {
    return { settingId }
  }

  if (stoneId && isSettingsPage) {
    return { stoneId }
  }

  return {}
}

export function flushQuery(query, params = []) {
  const itemsParams = Object.keys(invert(parametersNamesMap))
  let paramsList = [
    'page',
    'guid',
    'availability',
    ...validUrlFilterNames,
    ...itemsParams
  ]
  if (params.length) paramsList = params
  return omit(query, paramsList)
}

function isStonesDynamicPage(route) {
  return !route.path?.includes('settings')
}

function isSettingsDynamicPage(route) {
  return route.path?.includes('settings')
  // return route.path.includes('settings')
}

// combining two colors objects for sapphires
function transformListings(extraListings, template) {
  return Object.entries(extraListings).map(([key, item]) => {
    const transformedItem = {
      name: item.name,
      children: template[0].children || [],
      parents: template[0].parents || [],
      itemsCount: {
        stone: item.itemsCount?.stone || 0,
        pair: item.itemsCount?.pair || 0,
        ring: item.itemsCount?.ring || 0,
        earring: item.itemsCount?.earring || 0,
        necklace: item.itemsCount?.necklace || 0,
        pendant: item.itemsCount?.pendant || 0,
        bracelet: item.itemsCount?.bracelet || 0,
        cufflink: item.itemsCount?.cufflink || 0,
        brooch: item.itemsCount?.brooch || 0,
        weddingBand: item.itemsCount?.weddingBand || 0,
        engagement: item.itemsCount?.engagement || 0
      }
    }

    return transformedItem
  })
}

export function mergeAndMap(colors, extraListings) {
  // excluded by key item.kebabCase
  const excludeNameList = ['crystal', 'cabochons']

  const transformedExtraListings = transformListings(extraListings, colors)

  const mergedColors = [...colors, ...transformedExtraListings]

  const excludeMap = extraListingsExcludeObj.reduce((map, item) => {
    map[item.kebabCase] = item
    return map
  }, {})

  let finalObj = mergedColors.map((color) => ({
    ...color,
    kebabCase: kebabCase(color.name),
    camelCase: camelCase(color.name),
    routes: {
      stone: `${replaceKeysWithValues(kebabCase(color.name))}-sapphires`,
      pair: `${replaceKeysWithValues(kebabCase(color.name))}-sapphire-pairs`,
      ring: `${replaceKeysWithValues(kebabCase(color.name))}-sapphire-rings`,
      engagement: `${replaceKeysWithValues(
        kebabCase(color.name)
      )}-sapphire-engagement-rings`
    }
  }))

  finalObj = finalObj.map((item) =>
    excludeNameList.includes(item.kebabCase) && excludeMap[item.kebabCase]
      ? excludeMap[item.kebabCase]
      : item
  )

  return finalObj
}

function replaceKeysWithValues(str) {
  const replacements = {
    'Recom.': 'recommended',
    Recom: 'recommended',
    recom: 'recommended'
  }
  return Object.keys(replacements).reduce((result, key) => {
    return replace(result, key, replacements[key])
  }, str)
}

const extraListingsExcludeObj = [
  {
    id: '7f16aeef-007f-45d5-9aec-343de3027671',
    name: 'Cabochon Sapphires',
    img: 'mainMenu/stones/cabochon@2x.png',
    kebabCase: 'cabochons',
    camelCase: 'cabochons',
    parents: [],
    children: [],
    src: 'common/shopType/color/cabochons@2x.png',
    url: 'cabochons',
    routes: {
      engagement: 'cabochons-sapphire-engagement-rings',
      occasion: 'cabochons-sapphire-rings',
      pair: 'cabochons-sapphire-pairs',
      ring: 'cabochons-sapphire-rings',
      stone: 'cabochons'
    },
    to: {
      name: 'cabochons'
    },
    itemsCount: {
      bracelet: 0,
      brooch: 0,
      cufflink: 0,
      earring: 0,
      necklace: 0,
      pair: 0,
      pendant: 0,
      ring: 0,
      stone: 1,
      weddingBand: 0
    }
  },
  {
    id: 'a8c2413f-2378-4ba3-8bf1-287903d3f7d8',
    name: 'Crystal Sapphires',
    img: 'mainMenu/stones/crystal@2x.png',
    camelCase: 'crystal',
    kebabCase: 'crystal',
    parents: [],
    children: [],
    src: 'common/shopType/color/crystal-sapphires@2x.png',
    url: 'sapphire-crystals',
    routes: {
      stone: 'sapphire-crystals'
    },
    to: {
      name: 'sapphire-crystals'
    },
    itemsCount: {
      bracelet: 0,
      brooch: 0,
      cufflink: 0,
      earring: 0,
      necklace: 0,
      pair: 0,
      pendant: 0,
      ring: 0,
      stone: 1,
      weddingBand: 0
    }
  }
]

// Sort order for landing pages of sapphires NSC - key kebabCase
export const sortOrdersLandingPageShopType = {
  color: [
    'blue',
    'pink',
    'yellow',
    'padparadscha',
    'white',
    'green',
    'purple',
    'unique-colored',
    'curiosities-bi-color',
    'montana',
    'star',
    'cabochons',
    'crystal',
    'gemologist-recom'
  ],
  pair: [
    'blue',
    'pink',
    'yellow',
    'padparadscha',
    'white',
    'green',
    'purple',
    'unique-colored',
    'curiosities-bi-color',
    'montana',
    'star',
    'cabochons',
    'crystal',
    'gemologist-recom'
  ],
  engagement: [
    'blue',
    'white',
    'pink',
    'purple',
    'yellow',
    'green',
    'peach',
    'padparadscha',
    'montana'
  ],
  ring: [
    'blue',
    'white',
    'pink',
    'purple',
    'yellow',
    'green',
    'peach',
    'padparadscha',
    'montana'
  ],
  jewelry: [
    'engagement-rings',
    'rings',
    'earrings',
    'necklaces-pendants',
    'bracelets',
    'brooches',
    'vintage-inspired',
    'mens-rings',
    'cufflinks'
  ],
  wedding: [
    'plain-bands',
    'blue-sapphire',
    'blue-sapphire-diamonds',
    'pink-sapphire',
    'pink-sapphire-diamonds',
    'diamonds'
  ]
}
