import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import get from 'lodash/get'
import {
  bands,
  settings,
  stonesByCategory,
  jewelryByCategory,
  settingsByCategory,
  jewelry
} from '~~/utils/definitions/defaults'
import { serializeWeddingBandsDetails } from '~~/utils/serializators'
import { DETAILS } from '~~/store/modules/productDetails'
import {
  normalizeStonesDetails,
  normalizeWeddingBandsDetails
} from '~~/utils/normalizers'
import { getPreferableRingSize, guid } from '~~/utils/utils'

export default class FetchDetails {
  constructor(store, params, api, cookies) {
    this.api = api
    this.store = store
    this.preferableRingSizeId = getPreferableRingSize(cookies)
    this.commit = store.commit
    this.getters = store.getters
    this.force = params.force
    this.guid = params.guid || null // if guid exists we should keep it
    this.parent = params.parent || null // parent guid in case of change stone/setting in custom item
    this.id = params.id
    this.engraving = params.engraving
    this.vedicSetting = params.vedicSetting
    this.type = params.type
    this.dataType = params.dataType || 1
    this.metalTypeCode = params.metalTypeCode || null
    this.ringSize = params.size || params.ringSize || this.preferableRingSizeId
    this.width = params.width || params.ringWidth || 0
    this.finish = params.finish
    this.sideStones = this.getSideStones(params) || []
    this.isDirty = params.isDirty || false
  }

  getSideStones(params) {
    if (params.sideStones) return params.sideStones
    const sideStonesParams = ['grade', 'weight', 'position', 'stoneType']
    if (sideStonesParams.some((p) => p in params)) {
      const resultParams = []
      if (!params.grade) return []
      for (let i = 0; i < params.grade.length; i++) {
        const obj = { clarityId: 3 }
        obj.stoneTypeId = Number(params.stoneType[i])
        obj.clarityId = Number(params.clarity[i])
        obj.gradeId = Number(params.grade[i])
        obj.weight = params.weight[i]
        obj.position = params.position[i]
        resultParams.push(obj)
      }
      return resultParams
    }
    return []
  }

  get isFetched() {
    if (this.force) return false // if fast flag - skip all conditions, just fetch
    if ([...bands, ...settings].includes(this.type)) {
      const id = get(this.getters, 'productDetails.id', '').toLowerCase()
      const metalTypeCode = get(
        this.getters,
        'productDetails.metalTypeCode',
        ''
      ).toLowerCase()
      const ringSize = get(
        this.getters,
        'productDetails.selectedOptions.ringSize.key',
        ''
      )

      return (
        id === this.id &&
        metalTypeCode === this.metalTypeCode &&
        ringSize === this.ringSize
      )
    }
    return get(this.getters, 'productDetails.id', '').toLowerCase() === this.id
  }

  get fetchType() {
    if (jewelry.includes(this.type)) return 'jewelryList'
    if (settings.includes(this.type)) return 'settingsList'
    return this.type
  }

  async fetch() {
    if (this.isFetched) return
    const serverData = await this[`${this.fetchType}ItemFetch`]()
    const normalizedData = this.normalize(serverData)
    normalizedData.guid = this.guid || guid()
    if (this.parent) normalizedData.parentGuid = this.parent
    this.commit(DETAILS.FETCH_DETAILS, normalizedData)
  }

  makeBandsQuery() {
    return serializeWeddingBandsDetails({
      id: this.id,
      metalTypeCode: this.metalTypeCode,
      size: this.ringSize,
      width: this.width,
      sideStones: this.sideStones
    })
  }

  makeSettingsQuery() {
    return {
      id: this.id,
      metalTypeCode: this.metalTypeCode
    }
  }

  normalize(serverData) {
    const data = cloneDeep(serverData)
    const category = data.category
    if (stonesByCategory.includes(category)) return this.normalizeStones(data)
    if (category === 'Ring') return this.normalizeRings(data)
    if (jewelryByCategory.includes(category))
      return this.normalizeJewelries(data)
    if (category === 'Wedding Band') return this.normalizeWeddingBands(data)
    if (category === 'Plain Band') return this.normalizePlainBands(data)
    if (settingsByCategory.includes(category))
      return this.normalizeSettings(data)
  }

  /**
   * Apply initial data received from the server
   * @param {object} item
   * @param {object} options possible values
   * @returns {{object}} selected options object
   */
  applyInitialData(item, options = {}) {
    const { initialData } = item
    if (!initialData) return {}
    const result = {}
    Object.keys(initialData).forEach((key) => {
      if (key === 'vedicSetting') {
        result[key] = this[key] || initialData[key] // first of all selected vedic and then from initialData
        return
      }
      if (key === 'engraving') {
        result[key] = this[key] || initialData[key] // first of all selected engraving and then from initialData
        return
      }
      let value = this[key] || initialData[key]
      if (key === 'ringSize')
        value = this[key] || this.preferableRingSizeId || initialData[key]
      const arrayOfValues = options[key]
      if (Array.isArray(arrayOfValues)) {
        result[key] =
          arrayOfValues.find((s) => s.key === value) || arrayOfValues[0]
      }
    })
    return result
  }

  applySelectedOptions(item, options) {
    const oldItem = this.store.state.productDetails
    const selectedOptions = {
      ...item.selectedOptions,
      vedicSetting: !!this.vedicSetting,
      ...omit(oldItem.selectedOptions, ['metalName', 'metalTypeCode'])
    }

    if (this.ringSize) {
      if (!get(options, 'ringSize', []).length) return selectedOptions
      const ringSize = options.ringSize.find((rs) => rs.key === this.ringSize)
      if (!ringSize) return selectedOptions
      selectedOptions.ringSize = ringSize
    }

    return selectedOptions
  }

  setSelectedOptions(data, options) {
    const oldItem = this.store.state.productDetails
    if (oldItem.id === data.id) {
      if (isEmpty(oldItem.selectedOptions))
        return this.applyInitialData(data, options)
      return this.applySelectedOptions(data, options)
    }
    return this.applyInitialData(data, options)
  }

  normalizeStones(data) {
    data = normalizeStonesDetails(data)
    data.isDirty = this.isDirty
    data.selectedOptions = this.applyInitialData(data)
    return data
  }

  normalizeSettings(data) {
    const options = {}
    data.isDirty = this.isDirty
    if (data.category === 'Setting_Ring') options.ringSize = data.sizes
    data.selectedOptions = this.setSelectedOptions(data, options)
    return data
  }

  normalizeRings(data) {
    const ringSizes = get(this.getters, 'productDetailsOptions.ringSizes', [])
    const options = {
      ringSize: ringSizes
    }
    data.isDirty = this.isDirty
    data.selectedOptions = this.applyInitialData(data, options)
    return data
  }

  normalizeWeddingBands(data) {
    const newData = this.normalizePlainBands(normalizeWeddingBandsDetails(data))
    newData.selectedOptions.sideStoneOptions = this.sideStones.length
      ? this.setSelectedSideStonesOptions(newData.sideStoneOptions)
      : this.setDefaultSideStonesOptions(newData.sideStoneOptions)
    return newData
  }

  normalizePlainBands(data) {
    const metalTypeIndex = data.metalTypes.findIndex(
      (metal) => metal.key === data.metalTypeCode
    )

    const finish = data.finish.find((f) => f.key === this.finish)

    const options = {
      ringSize: data.sizes,
      ringWidth: data.widths
    }
    data.selectedOptions = this.applyInitialData(data, options)
    data.isDirty = this.isDirty

    data.selectedOptions.metalType = data.metalTypes[metalTypeIndex]
    data.selectedOptions.finish = finish || data.finish[0]

    return data
  }

  setSelectedSideStonesOptions(availableSideStones) {
    const sideStoneOptions = []
    availableSideStones.forEach((option, index) => {
      sideStoneOptions[index] = {
        position: option.position,
        grade: option.grades.find(
          (g) => g.id === this.sideStones[index].gradeId
        ),
        stoneType: option.stoneTypes.find(
          (g) => g.id === this.sideStones[index].stoneTypeId
        ),
        weight: option.weights.find(
          (w) => w.id === this.sideStones[index].weight
        ),
        clarity: option.clarities.find(
          (c) => c.id === this.sideStones[index].clarityId
        )
      }
    })
    return sideStoneOptions
  }

  setDefaultSideStonesOptions(availableSideStones) {
    const sideStoneOptions = []
    availableSideStones.forEach((option, index) => {
      const stoneType = option.stoneTypes[0]
      const grade = option.grades.filter(
        (w) => w.stoneTypeId === stoneType.id
      )[0]
      const weight = option.weights.filter(
        (w) => w.stoneTypeId === stoneType.id && w.gradeId === grade.id
      )[0]
      sideStoneOptions[index] = {
        position: option.position,
        grade,
        stoneType,
        weight,
        clarity: option.clarities[0]
      }
    })
    return sideStoneOptions
  }

  normalizeJewelries(data) {
    data.selectedOptions = {}
    data.isDirty = this.isDirty
    return data
  }

  async stonesListItemFetch() {
    const details = await this.api.details.fetchStoneDetails(this.id)
    return details
  }

  async stonePairsListItemFetch() {
    const details = await this.api.details.fetchPairsDetails(this.id)
    return details
  }

  async jewelryListItemFetch() {
    let details = {}
    if (this.dataType === 1) {
      // if simple jewelry, not MTO
      await this.store.dispatch('fetchDetailsOptions')
      details = await this.api.details.fetchJewelryDetails(this.id)
      await this.store.dispatch('confirmRingSizeReset')
    }
    return details
  }

  async settingsListItemFetch() {
    const query = this.makeSettingsQuery()
    const details = await this.api.details.fetchSettingsDetails(query)
    return details
  }

  async weddingBandsItemFetch() {
    const query = this.makeBandsQuery()
    const details = await this.api.details.fetchBandsDetails(query)
    return details
  }

  async weddingBandsPlainItemFetch() {
    const query = this.makeBandsQuery()
    const details = await this.api.details.fetchPlainBandsDetails(query)
    return details
  }
}
