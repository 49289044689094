import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import kebabCase from 'lodash/kebabCase'
import { domainsMap, companyName } from './definitions/defaults.js'
import { protocol } from './config'
import {
  caratURLFormatter,
  itemNameFormatterNoLocale,
  capitalize
} from './formatters'

import { stones } from './config/gemstoneConfig.js'
import { colors } from './config/sapphireConfig.js'

export const stoneType = {
  ruby: 'ruby',
  emerald: 'emerald',
  gemstone: 'gemstone'
}
const maxItemsCount = 1000

export const defaultGemListQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    id: null,
    settingId: null,
    shapes: [],
    origins: [],
    price: {
      min: null,
      max: null
    },
    weight: {
      min: null,
      max: null
    },
    width: {
      min: null,
      max: null
    },
    length: {
      min: null,
      max: null
    },
    stoneTypes: [],
    colors: [],
    sortByWeight: 0,
    sortByPrice: 0,
    featuredFirst: 0,
    newFirst: 1
  }
}

export const defaultJewelryListQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    id: null,
    metals: [],
    categories: ['Ring', 'Earring', 'Necklace', 'Pendant', 'Bracelet'],
    shapes: [],
    price: {
      min: null,
      max: null
    },
    weight: {
      min: null,
      max: null
    },
    enhancements: [],
    stoneTypes: [],
    colors: [],
    sortByWeight: 0,
    sortByPrice: 0,
    featuredFirst: 0,
    newFirst: 1
  }
}

export const defaultWeddingBandsPlainQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    id: null,
    categoryId: 1,
    metals: [],
    styles: [],
    stoneTypes: [],
    stoneShapes: [],
    sortBy: 'NewFirst',
    sortOrder: 1
  }
}

export const defaultWeddingBandsQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    id: null,
    categoryId: 2,
    metals: [],
    styles: [],
    stoneTypes: [],
    stoneShapes: [],
    sortBy: 'NewFirst',
    sortOrder: 1
  }
}

export const defaultRingSettingsQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    category: 'ring',
    stoneId: null,
    metals: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

export const defaultEarringSettingsQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    category: 'earring',
    stoneId: null,
    metals: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

export const defaultBraceletSettingsQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    category: 'bracelet',
    stoneId: null,
    metals: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

export const defaultPendantSettingsQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    category: 'pendant',
    stoneId: null,
    metals: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

export const defaultNecklaceSettingsQuery = {
  pageNumber: 1,
  calculateCount: false,
  pageSize: maxItemsCount,
  query: {
    category: 'necklace',
    stoneId: null,
    metals: [],
    centerStoneShapes: [],
    sideStoneShapes: [],
    styles: [],
    sortBy: 'FeaturedFirst',
    sortOrder: 1
  }
}

const categories = [
  { category: 'Ring', module: 'ringsList' },
  { category: 'Brooch', module: 'broochesList' },
  { category: 'Cufflink', module: 'cufflinksList' },
  { category: 'Stone', module: 'stonesList' },
  { category: 'Pair', module: 'stonePairsList' },
  { category: 'Earring', module: 'earringsList' },
  { category: 'Pendant', module: 'necklacesPendantsList' },
  { category: 'Necklace', module: 'necklacesPendantsList' },
  { category: 'Bracelet', module: 'braceletsList' },
  { category: 'Bangle', module: 'braceletsList' },
  { category: 'Plain Band', module: 'weddingBandsPlain' },
  { category: 'Wedding Band', module: 'weddingBands' },
  { category: 'Setting_Ring', module: 'ringSettings' },
  { category: 'Setting_Earring', module: 'earringSettings' },
  { category: 'Setting_Bracelet', module: 'braceletSettings' },
  { category: 'Setting_Pendant', module: 'pendantSettings' },
  { category: 'Setting_Necklace', module: 'necklaceSettings' },
  { category: 'Custom', module: 'custom' }, // custom jewelry
  { category: 'Wax', module: 'wax' } // custom jewelry wax model
]

const createProductListTypeByCategory = (categories) => {
  return categories.reduce((acc, { category, module }) => {
    acc[category] = module
    return acc
  }, {})
}

export const productListTypeByCategory =
  createProductListTypeByCategory(categories)

export const jewelry = [
  'cufflinksList',
  'broochesList',
  'braceletsList',
  'earringsList',
  'necklacesPendantsList',
  'ringsList'
]

export const previewJewelry = [
  'earringsList',
  'braceletsList',
  'necklacesPendantsList',
  'ringsList'
]

export const settings = [
  'ringSettings',
  'earringSettings',
  'braceletSettings',
  'pendantSettings',
  'necklaceSettings'
]

export const bands = ['weddingBandsPlain', 'weddingBands']

export const bandsByCategory = ['Plain Band', 'Wedding Band']

export const settingsByCategory = [
  'Setting_Ring',
  'Setting_Earring',
  'Setting_Bracelet',
  'Setting_Pendant',
  'Setting_Necklace'
]

function getColorFromRoute(route) {
  const dynamicListing = route?.params?.dynamicListing
  return dynamicListing
    ? colors.find((color) =>
        Object.values(color.routes).includes(dynamicListing)
      )
    : null
}

export function getItemListPath(item, route) {
  if (item.category === 'Stone') return stoneListPathMap(item, route)
  if (item.category === 'Pair') return stonePairListPathMap(item, route)
}

export function stoneListPathMap(item, route) {
  const defaultRoute = {
    ruby: 'rubies',
    emerald: 'emeralds'
  }
  const siteName = process.env.siteName

  if (defaultRoute[siteName]) {
    return defaultRoute[siteName]
  }

  if (siteName === 'sapphire') {
    return getSapphireStoneListingPath(item, route)
  }

  return stones.find((stone) => stone.name === item.stoneType)?.kebabCase
}

/**
 * Returns a path for a given item in the sapphire collection.
 * @param {Object} item - The item to generate the path for.
 * @param {Object} route - The current route.
 * @returns {String} The path for the given item.
 */
function getSapphireStoneListingPath(item, route) {
  const routeColor = getColorFromRoute(route)
  if (!routeColor) {
    // keep "if" order below. It's important
    if (
      item.colorCategories?.every((color) =>
        ['Multi Color', 'Bi Color'].includes(color)
      )
    )
      return 'curiosities-bi-color-sapphires'
    if (item.origin === 'Montana') return 'montana-sapphires'
    if (item.webCategory === 2) return 'gemologist-recommended-sapphires'
    if (item.cuttingStyle === 'Crystal') return 'sapphire-crystals'
    if (item.isStar) return 'star-sapphires'
    if (item.cuttingStyle === 'Cabochon') return 'cabochons'
    // return 'sapphires'
  }
  const categoryColorIndex = routeColor
    ? item.colorCategories.indexOf(routeColor.name)
    : -1
  const colorName =
    item.colorCategories[categoryColorIndex >= 0 ? categoryColorIndex : 0]
  const color = colors.find((color) => color.name === colorName)

  return color?.routes.stone || 'unique-colored-sapphires'
}

export function stonePairListPathMap(item, route) {
  const defaultRoute = {
    ruby: 'rubies/matched-pairs-of-rubies',
    emerald: 'emeralds/matched-pairs-of-emeralds'
  }
  const siteName = process.env.siteName

  if (defaultRoute[siteName]) return defaultRoute[siteName]

  if (siteName === 'sapphire') return getSapphirePairsListingPath(item, route)

  return (
    stones.find(
      (stone) => stone.name === get(item, 'stones[0].stoneType', item.stoneType)
    ).kebabCase + '-pairs'
  )
}

function getSapphirePairsListingPath(item, route) {
  const routeColor = getColorFromRoute(route)
  if (!routeColor) {
    // keep "if" order below. It's important
    if (get(item, 'stones[0].webCategory', item.webCategory) === 2)
      return 'gemologist-recommended-sapphire-pairs'
    if (get(item, 'stones[0].cuttingStyle', item.cuttingStyle) === 'Crystal')
      return 'crystal-sapphire-pairs' // this type of pairs is not exists at the moment of development
    if (get(item, 'stones[0].isStar', item.isStar)) return 'star-sapphire-pairs'
    if (get(item, 'stones[0].cuttingStyle', item.cuttingStyle) === 'Cabochon')
      return 'cabochon-sapphire-pairs' // this type of pairs is not exists at the moment of development
    return 'sapphire-pairs'
  }
  const colorCategories = get(
    item,
    'stones[0].colorCategories',
    item.colorCategories
  )
  const categoryColorIndex = routeColor
    ? colorCategories.indexOf(routeColor.name)
    : -1
  const colorName =
    colorCategories[categoryColorIndex >= 0 ? categoryColorIndex : 0]
  const color = colors.find((color) => color.name === colorName)

  return color?.routes.pair
}

const weddingBandsPathMap = {
  ruby: 'ruby-wedding-rings-bands',
  emerald: 'emerald-wedding-rings-bands'
}

const jewelryPathMap = {
  ruby: 'ruby-jewelry',
  emerald: 'emerald-jewelry',
  gemstone: {
    Bracelet: 'gemstone-bracelets',
    Brooch: 'gemstone-brooches',
    Cufflink: 'gemstone-cufflinks',
    Earring: 'gemstone-earrings',
    Pendant: 'gemstone-necklaces-pendants',
    Necklace: 'gemstone-necklaces-pendants',
    Ring: {
      ...stones.reduce((result, stone) => {
        result[stone.name] = stone.kebabCase + '-rings'
        return result
      }, {})
    }
  },
  sapphire: {
    Bracelet: 'sapphire-bracelets',
    Brooch: 'sapphire-brooches',
    Cufflink: 'sapphire-cufflinks',
    Earring: 'sapphire-earrings',
    Pendant: 'sapphire-necklaces-pendants',
    Necklace: 'sapphire-necklaces-pendants',
    Ring: {
      ...colors.reduce((result, color) => {
        result[color.name] = color.routes.ring
        return result
      }, {})
    }
  }
}

const mensSettingsPathMap = {
  ruby: 'mens-ring-settings',
  emerald: 'mens-ring-settings',
  gemstone: 'mens-gemstone-ring-settings'
}

const settingsPathMap = {
  ruby: {
    Setting_Ring: 'ruby-engagement-ring-settings',
    Setting_Earring: 'ruby-earring-settings',
    Setting_Bracelet: 'ruby-bracelet-settings',
    Setting_Pendant: 'ruby-necklace-pendant-settings',
    Setting_Necklace: 'ruby-necklace-pendant-settings'
  },
  emerald: {
    Setting_Ring: 'emerald-engagement-ring-settings',
    Setting_Earring: 'emerald-earring-settings',
    Setting_Bracelet: 'emerald-bracelet-settings',
    Setting_Pendant: 'emerald-necklace-pendant-settings',
    Setting_Necklace: 'emerald-necklace-pendant-settings'
  },
  gemstone: {
    Setting_Ring: 'gemstone-ring-settings',
    Setting_Earring: 'gemstone-earring-settings',
    Setting_Bracelet: 'gemstone-bracelet-settings',
    Setting_Pendant: 'gemstone-necklace-pendant-settings',
    Setting_Necklace: 'gemstone-necklace-pendant-settings'
  },
  sapphire: {
    Setting_Ring: 'sapphire-engagement-ring-settings',
    Setting_Earring: 'sapphire-earring-settings',
    Setting_Bracelet: 'sapphire-bracelet-settings',
    Setting_Pendant: 'sapphire-necklace-pendant-settings',
    Setting_Necklace: 'sapphire-necklace-pendant-settings'
  }
}

export const normRouteName = function (name) {
  if (!name) return ''
  return name.replace(/_{3}\w{2}/gi, '')
}

class DetailsPath {
  constructor(item, route) {
    this.item = item
    this.route = route
    this.dataType = item.dataType
    this.path = 'index'
    this.type = productListTypeByCategory[item.category]
    this.id = String(item.id).toLowerCase()
  }

  getPath() {
    if (!this.item) return this.path
    return `/${this[`${this.type}Path`]()}/`
  }

  stonesListPath() {
    const path = getItemListPath(this.item, this.route)
    const carat = caratURLFormatter(calculateTotalWeight(this.item)) + 'ct'
    const origin = this.originNormalize(this.item.origin)
    const shape = this.shapeNormalize(this.item.shape)
    const stoneType = kebabCase(this.item.stoneSubType || this.item.stoneType)

    const itemId = [carat, origin, shape, stoneType, this.id]
      .filter(Boolean)
      .join('-')
      .toLowerCase()

    return `${path}/${itemId}`
  }

  stonePairsListPath() {
    const path = getItemListPath(this.item, this.route)
    const carat = caratURLFormatter(calculateTotalWeight(this.item)) + 'ct'
    const origin = this.originNormalize(
      get(this.item, 'stones[0].origin', this.item.origin)
    )
    const shape = this.shapeNormalize(
      get(this.item, 'stones[0].shape', this.item.shape)
    )
    const stoneType = kebabCase(
      get(this.item, 'stones[0].stoneSubType', this.item.stoneSubType) ||
        get(this.item, 'stones[0].stoneType', this.item.stoneType)
    )

    const itemId = [carat, origin, shape, stoneType, 'pair', this.id]
      .filter(Boolean)
      .join('-')
      .toLowerCase()

    return `${path}/${itemId}`
  }

  weddingBandsPath() {
    let path = weddingBandsPathMap[process.env.siteName]
    if (!path) path = this.getWeddingBandPath(this.item)
    const bandType = 'band'
    const metalTypeCode = this.item.metalTypeCode.toLowerCase()
    const metalName = this.metalNameNormalize(
      this.item.metalName || this.item.metalType
    )

    return `${path}/${metalName}-${bandType}-${this.id}${metalTypeCode}`
  }

  getWeddingBandPath(item) {
    if (item.colors?.includes('Blue')) {
      if (item.colors.includes('White')) {
        return 'blue-sapphire-diamond-wedding-rings-bands'
      }
      return 'blue-sapphire-wedding-rings-bands'
    }
    if (item.colors?.includes('Pink')) {
      if (item.colors.includes('White')) {
        return 'pink-sapphire-diamond-wedding-rings-bands'
      }
      return 'pink-sapphire-wedding-rings-bands'
    }
    return 'diamond-wedding-rings-bands'
  }

  weddingBandsPlainPath() {
    let path = 'wedding-bands-without-gemstone'
    if (['sapphire'].includes(process.env.siteName))
      path = 'plain-wedding-rings-bands'
    const bandType = 'plain-band'
    const metalTypeCode = this.item.metalTypeCode.toLowerCase()
    const metalName = this.metalNameNormalize(
      this.item.metalName || this.item.metalType
    )

    return `${path}/${metalName}-${bandType}-${this.id}${metalTypeCode}`
  }

  ringsListPath() {
    return this.jewelryPath()
  }

  cufflinksListPath() {
    return this.jewelryPath()
  }

  broochesListPath() {
    return this.jewelryPath()
  }

  earringsListPath() {
    return this.jewelryPath()
  }

  necklacesPendantsListPath() {
    return this.jewelryPath()
  }

  braceletsListPath() {
    return this.jewelryPath()
  }

  ringSettingsPath() {
    return this.settingsPath()
  }

  earringSettingsPath() {
    return this.settingsPath()
  }

  braceletSettingsPath() {
    return this.settingsPath()
  }

  pendantSettingsPath() {
    return this.settingsPath()
  }

  necklaceSettingsPath() {
    return this.settingsPath()
  }

  waxPath() {
    return this.customPath()
  }

  customPathParams() {
    if (isEmpty(this.item.customSetting) || isEmpty(this.item.customStone)) {
      return 'design-your-own'
    }

    const getStoneProperty = (prop, fallback = '') =>
      this.item.customStone?.[prop] ||
      get(this.item, `customStone.stones[0].${prop}`, fallback)

    const settingId = getItemId(this.item.customSetting).toLowerCase()
    const stoneId = getItemId(this.item.customStone).toLowerCase()
    const color = this.colorNormalize(getStoneProperty('color'))

    const categoryMap = {
      Setting_Earring: 'earrings',
      Pendant: 'necklace-pendant',
      Necklace: 'necklace-pendant'
    }
    const jewelryType =
      categoryMap[this.item.customSetting.category] ||
      this.item.customSetting.category.replace('Setting_', '')

    const shape = this.shapeNormalize(getStoneProperty('shape'))

    const carat = caratURLFormatter(calculateTotalWeight(this.item))

    const metalName = this.metalNameNormalize(this.item.customSetting.metalName)
    const metalTypeCode = this.item.customSetting.metalTypeCode

    const stoneType = kebabCase(
      getStoneProperty('stoneSubType', this.item.stoneSubType) ||
        getStoneProperty('stoneType', this.item.stoneType)
    )

    return {
      item: this.item,
      settingId,
      stoneId,
      color,
      jewelryType,
      shape,
      carat,
      metalName,
      metalTypeCode,
      stoneType
    }
  }

  customPreviewParams() {
    const color = this.colorNormalize(this.item.color || '')
    let category = this.item.category || ''
    if (this.item.category === 'Earring') category = 'earrings'
    const shape = this.shapeNormalize(this.item.shape || '')
    const weight = calculateTotalWeight(this.item)
    const stoneWeight = weight ? caratURLFormatter(weight) : ''
    const metalName = this.metalNameNormalize(this.item.metalName)
    const metalTypeCode = this.item.metalTypeCode
    const stoneType = this.item.stoneSubType || this.item.stoneType
    return {
      item: this.item,
      color,
      category,
      shape,
      stoneWeight,
      metalName,
      metalTypeCode,
      stoneType
    }
  }

  customPath() {
    if (this.dataType === 3) return this.dmtoPath()

    const {
      settingId,
      stoneId,
      color,
      jewelryType,
      shape,
      carat,
      metalName,
      stoneType
    } = this.customPathParams()

    const customId = [
      color,
      stoneType,
      jewelryType,
      shape,
      `${carat}ct`,
      metalName,
      settingId,
      stoneId
    ]
      .filter(Boolean)
      .join('-')
      .toLowerCase()
    return `design-your-own/review/${customId}`
  }

  dmtoPath() {
    const {
      item,
      color,
      jewelryType,
      shape,
      carat,
      stoneType,
      metalName,
      metalTypeCode
    } = this.customPathParams()

    const designerName = kebabCase(item.designer.designerName)
    const itemName = kebabCase(item.designer.itemName)
    const customId = [
      itemName,
      color,
      stoneType,
      jewelryType,
      shape,
      `${carat}ct`,
      metalName,
      this.item.preview?.itemRealId,
      metalTypeCode
    ]
      .filter(Boolean)
      .join('-')
      .toLowerCase()
    return `featured-designers/${designerName}/${customId}`
  }

  // for listing pages
  customPreviewPath() {
    const { color, category, stoneType, shape, stoneWeight, metalName } =
      this.customPreviewParams()

    const customId = [
      color,
      stoneType,
      category,
      shape,
      `${stoneWeight}ct`,
      metalName,
      this.id
    ]
      .filter(Boolean)
      .join('-')
      .toLowerCase()
    return `design-your-own/review/${customId}`
  }

  // for listing pages
  dmtoPreviewPath() {
    const {
      item,
      color,
      category,
      shape,
      stoneWeight,
      metalName,
      metalTypeCode,
      stoneType
    } = this.customPreviewParams()
    const designerName = kebabCase(item.designerName)
    const itemName = kebabCase(item.name)

    const customId = [
      itemName,
      color,
      stoneType,
      category,
      shape,
      `${stoneWeight}ct`,
      metalName,
      this.id,
      metalTypeCode
    ]
      .filter(Boolean)
      .join('-')
      .toLowerCase()
    return `featured-designers/${designerName}/${customId}`
  }

  getKeyLocales() {
    let category = this.isCustomItem
      ? this.customItem?.customSetting?.category
      : this.productDetails?.category
    if (category.includes('Setting_')) {
      category = this.itemDetails.category
      if (category === 'Pair' || category === 'Earring') {
        return 'pair'
      }
      return 'stone'
    } else {
      return category.toLowerCase()
    }
  }

  jewelryPath() {
    if (this.dataType === 2) return this.customPreviewPath()
    if (this.dataType === 3) return this.dmtoPreviewPath()
    // if (this.dataType === 2) return `design-your-own/review/${this.id}`
    const path = this.getJewelryListingPath()
    const carat = caratURLFormatter(calculateTotalWeight(this.item)) + 'ct'
    let category = this.item.category.toLowerCase()
    const stoneType = kebabCase(
      get(this.item, 'stones[0].stoneType', this.item.stoneType)
    )

    const color =
      this.colorNormalize(this.item.color) ||
      this.colorNormalize(this.item.centerStones[0].color) ||
      ''

    const shape = this.shapeNormalize(
      get(this.item, 'centerStones[0].shape', this.item.shape)
    )

    const metalName = this.metalNameNormalize(
      this.item.metalName || this.item.metalType || this.item.metal.value
    )
    if (this.item.category === 'Earring') category = 'earrings'
    if (['Pendant', 'Necklace'].includes(this.item.category))
      category = 'necklace-pendant'

    const itemId = [
      color,
      stoneType,
      category,
      shape,
      carat,
      metalName,
      this.id
    ]
      .filter((p) => p)
      .join('-')
      .toLowerCase()

    return `${path}/${itemId}`
  }

  getJewelryListingPath() {
    const path = jewelryPathMap[process.env.siteName]
    if (typeof path === 'string') return path // NEC/NRC
    // NGC start
    if (['Ring'].includes(this.item.category)) {
      if (process.env.siteName === 'gemstone') {
        const stoneType = get(
          this.item,
          'stones[0].stoneType',
          this.item.stoneType
        )
        return path[this.item.category][stoneType]
      }
      if (process.env.siteName === 'sapphire') {
        const colorCategories =
          get(
            this.item,
            'stones[0].colorCategories',
            this.item.colorCategories
          ) || []

        for (const colorName of colorCategories) {
          const colorPath = path[this.item.category][colorName]
          if (colorPath) {
            return colorPath
          }
        }

        return 'all-sapphire-rings'
      }
      return path[this.item.category]
    }
    return path[this.item.category]
    // NGC end
  }

  settingsPath() {
    const prefix = ['ruby', 'emerald'].includes(process.env.siteName)
      ? 'design-your-own/setting/'
      : ''
    let path = ''
    if (/mens-ring-settings/g.test(normRouteName(this.route?.name))) {
      path = mensSettingsPathMap[process.env.siteName]
    } else {
      path = settingsPathMap[process.env.siteName][this.item.category]
    }
    const metalName = this.metalNameNormalize(
      this.item.metalName || this.item.metalType
    )

    const styleName = this.styleNormalize(get(this.item, 'styleNames[0]', ''))

    const metalTypeCode = (this.item.metalTypeCode || '').toLowerCase()
    const category = this.categoryNormalize(this.item.category)

    const itemId = [metalName, styleName, category, this.id + metalTypeCode]
      .filter(Boolean)
      .join('-')
      .toLowerCase()

    return `${prefix + path}/${itemId}`
  }

  categoryNormalize(category) {
    return category.replace('Setting_', '').toLowerCase()
  }

  styleNormalize(style) {
    if (!style) return ''
    return kebabCase(style)
  }

  colorNormalize(color) {
    if (!color) return ''
    return kebabCase(color)
  }

  originNormalize(origin) {
    if (!origin || origin.toLowerCase() === 'unknown') {
      return ''
    }
    return kebabCase(origin)
  }

  shapeNormalize(shape) {
    if (!shape) return ''
    return kebabCase(shape)
  }

  metalNameNormalize(metalName) {
    if (!metalName) return ''
    const cleanedName = metalName.replace('&', '').trim().toLowerCase()
    return cleanedName.split(/\s+/).join('-')
  }
}

export const calculateTotalWeight = (item) => {
  const stoneItem = item.customStone || item

  if (stoneItem.stones) {
    return stoneItem.stones.reduce((sum, stone) => sum + (stone.weight || 0), 0)
  }

  if (stoneItem.centerStones) {
    return stoneItem.centerStones.reduce((sum, stone) => {
      if (['gemstone'].includes(process.env.siteName)) {
        return sum + stone.totalWeight
      }
      return stone.stoneType.toLowerCase() === process.env.siteName
        ? sum + stone.totalWeight
        : sum
    }, 0)
  }
  return (
    stoneItem.weight ||
    stoneItem.stoneWeight ||
    stoneItem.weight ||
    stoneItem.centerStoneWeight
  )
}

export const getDetailsPath = function (item, route) {
  const path = new DetailsPath(item, route)
  return path.getPath()
}

export const getItemId = function (item, idBy) {
  if (idBy) return item[idBy]
  if (item.dataType === 2) return item.id // MTO listing items
  if (item.dataType === 3) return item.id // DMTO listing items
  if (item.category === 'Custom' || (item.customStone && item.customSetting))
    return getCustomItemId(item)
  const metalTypeCode = item.metalTypeCode || ''
  return `${item.id}${metalTypeCode}`
}

export function toIsoString(date) {
  const tzo = -date.getTimezoneOffset()
  const dif = tzo >= 0 ? '+' : '-'
  const pad = function (num) {
    const norm = Math.floor(Math.abs(num))
    return (norm < 10 ? '0' : '') + norm
  }

  return (
    date.getFullYear() +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    'T' +
    pad(date.getHours()) +
    ':' +
    pad(date.getMinutes()) +
    ':' +
    pad(date.getSeconds()) +
    dif +
    pad(tzo / 60) +
    ':' +
    pad(tzo % 60)
  )
}

export const getFeedItem = function (item) {
  const host = `${protocol}://${
    process.env.NODE_ENV !== 'production'
      ? domainsMap[process.env.siteName].devDomain
      : domainsMap[process.env.siteName].prodDomain
  }`

  const id =
    item.dataType === 2 ? item.designId : item.id + (item.metalTypeCode || '')
  const color =
    item.dataType === 2
      ? `${item.color}/${item.metalName}`
      : item.color || item.metalName

  const feedItem = {
    'g:id': id,
    'g:title': itemNameFormatterNoLocale(item),
    'g:image_link': item.imagePath,
    'g:custom_label_1': getCustomLabel(item),
    'g:availability':
      item.itemStatus.status === 3 ? 'out_of_stock' : 'in_stock',
    'g:description': item.description || '',
    'g:price': item.price + ' USD',
    'g:link': host + getDetailsPath(item),
    'g:mpn': id,
    'g:gender': getProductGender(item),
    'g:age_group': 'adult',
    'g:brand': companyName[process.env.siteName],
    'g:product_type': getProductType(item),
    'g:custom_label_0': getLabel(item),
    'g:google_product_category': getGoogleProductCategory(item),
    'g:condition': item.condition,
    'g:color': color,
    'g:min_handling_time': getMinTime(item),
    'g:max_handling_time': getMaxTime(item)
  }

  if (item.gtinCode) feedItem['g:gtin'] = item.gtinCode

  if (item.discountPrice !== item.price) {
    feedItem['g:sale_price'] = item.discountPrice + ' USD'
    feedItem['g:sale_price_effective_date'] = `${toIsoString(
      new Date(item.saleStart * 1000)
    )}/${toIsoString(new Date(item.saleEnd * 1000))}`
  }

  return feedItem
}

export const getSupplementalItem = function (item) {
  const id =
    item.dataType === 2 ? item.id : item.id + (item.metalTypeCode || '')

  const feedItem = {
    'g:id': id
  }

  if (item.images) feedItem['g:additional_image_link'] = item.images

  return feedItem
}

const priceRanges = {
  100: '0-100',
  250: '100-250',
  500: '250-500',
  1000: '500-1000',
  1500: '1000-1500',
  2000: '1500-2000',
  2500: '2000-2500',
  3000: '2500-3000',
  4000: '3000-4000',
  5000: '4000-5000',
  7500: '5000-7500',
  10000: '7500-10000',
  15000: '10000-15000',
  20000: '15000-20000',
  25000: '20000-25000',
  50000: '25000-50000',
  100000: '50000-100000'
}

function getCustomLabel(item) {
  const vPrice = item.discountPrice || item.price
  if (vPrice > Object.keys(priceRanges).pop()) return '100000+'
  let priceRange = Object.values(priceRanges).pop()
  Object.keys(priceRanges)
    .reverse()
    .some((key) => {
      if (vPrice <= key) priceRange = priceRanges[key]
      return vPrice > key
    })
  return priceRange
}

function getProductGender(item) {
  const { category } = item
  if (item.styleNames?.some((s) => s.includes("Men's"))) return 'male'
  if (['Stone', 'Pair'].includes(category)) return 'unisex'
  return 'female'
}

const productTypes = {
  ruby: {
    'Wedding Band': 'Home > Wedding Rings & Bands > Ruby Wedding Bands',
    'Plain Band': 'Home > Wedding Rings & Bands > Ruby Wedding Bands',
    Pendant: 'Home > Ruby Jewelry > Ruby Necklaces & Pendants',
    Necklace: 'Home > Ruby Jewelry > Ruby Necklaces & Pendants',
    Stone: 'Home > Loose Rubies > Rubies',
    Pair: 'Home > Loose Rubies > Ruby Pairs',
    Ring: 'Home > Ruby Jewelry > Ruby Rings',
    Earring: 'Home > Ruby Jewelry > Ruby Earrings',
    Bracelet: 'Home > Ruby Jewelry > Ruby Bracelets'
  },
  emerald: {
    'Wedding Band': 'Home > Wedding Rings & Bands > Emerald Wedding Bands',
    'Plain Band': 'Home > Wedding Rings & Bands > Emerald Wedding Bands',
    Pendant: 'Home > Emerald Jewelry > Emerald Necklaces & Pendants',
    Necklace: 'Home > Emerald Jewelry > Emerald Necklaces & Pendants',
    Stone: 'Home > Loose Emeralds > Emeralds',
    Pair: 'Home > Loose Emeralds > Emerald Pairs',
    Ring: 'Home > Emerald Jewelry > Emerald Rings',
    Earring: 'Home > Emerald Jewelry > Emerald Earrings',
    Bracelet: 'Home > Emerald Jewelry > Emerald Bracelets'
  },
  gemstone: {
    Pendant: 'Home > Gemstone Jewelry > Gemstone Necklaces & Pendants',
    Necklace: 'Home > Gemstone Jewelry > Gemstone Necklaces & Pendants',
    Stone: 'Home > Loose Gemstones > Gemstones',
    Pair: 'Home > Loose Gemstones  > Gemstone Pairs',
    Ring: 'Home > Gemstone Jewelry > Gemstone Rings',
    Earring: 'Home > Gemstone Jewelry > Gemstone Earrings',
    Bracelet: 'Home > Gemstone Jewelry > Gemstone Bracelets'
  },
  sapphire: {
    Pendant: 'Home > Sapphire Jewelry > Sapphire Necklaces & Pendants',
    Necklace: 'Home > Sapphire Jewelry > Sapphire Necklaces & Pendants',
    Stone: 'Home > Loose Sapphires > Sapphires',
    Pair: 'Home > Loose Sapphires  > Sapphire Pairs',
    Ring: 'Home > Sapphire Jewelry > Sapphire Rings',
    Earring: 'Home > Sapphire Jewelry > Sapphire Earrings',
    Bracelet: 'Home > Sapphire Jewelry > Sapphire Bracelets'
  }
}

function getProductType(item) {
  const { category } = item
  return (
    productTypes[process.env.siteName][category] ||
    `Home > ${capitalize(item.stoneType)} Jewelry`
  )
}

function getLabel(item) {
  const { category } = item
  const jewelry = ['Pendant', 'Necklace', 'Ring', 'Earring', 'Bracelet']
  if (['Wedding Band', 'Plain Band'].includes(category)) return 'WeddingBand'
  if (['Stone', 'Pair'].includes(category)) return 'Stone'
  if (item.dataType === 2) return 'MadeToOrderJewelry'
  if (jewelry.includes(category)) return 'ReadyToShipJewelry'
  return 'Jewelry'
}

const defaultMinDays = {
  Stone: 1,
  Pair: 1,
  Pendant: 1,
  Necklace: 1,
  Earring: 1,
  Ring: 1,
  Bracelet: 1,
  Custom: 14,
  'Wedding Band': 14,
  'Plain Band': 14
}

const defaultMaxDays = {
  Stone: 3,
  Pair: 3,
  Pendant: 4,
  Necklace: 4,
  Earring: 4,
  Ring: 4,
  Bracelet: 4,
  Custom: 28,
  'Wedding Band': 28,
  'Plain Band': 28
}

function getMinTime(item) {
  if (item.productionTimeFrom) return item.productionTimeFrom
  if (item.dataType === 2) return defaultMinDays.Custom
  return defaultMinDays[item.category]
}

function getMaxTime(item) {
  if (item.productionTimeTo) return item.productionTimeTo
  if (item.dataType === 2) return defaultMaxDays.Custom
  return defaultMaxDays[item.category]
}

function getGoogleProductCategory(item) {
  const { category } = item
  let gCategory = 188
  if (category === 'Bracelet') gCategory = 191
  if (['Ring', 'Wedding Band', 'Plain Band'].includes(category)) gCategory = 200
  if (category === 'Earring') gCategory = 194
  if (['Pendant', 'Necklace'].includes(category)) gCategory = 196

  return gCategory
}

export function getCustomItemId(item) {
  if (item.dataType === 3) return item.preview?.itemRealId
  const { customStone, customSetting } = item
  if (isEmpty(customStone) || isEmpty(customSetting)) return null
  return `${customSetting.id}${customSetting.metalTypeCode}-${customStone.id}`
}
