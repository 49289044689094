import get from 'lodash/get'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import isNil from 'lodash/isNil'
import cloneDeep from 'lodash/cloneDeep'
import kebabCase from 'lodash/kebabCase.js'
import camelCase from 'lodash/camelCase.js'
import { guid, getItemId } from '../utils'
import compareList from './compareList'
import wordpress from './wordpress'

export { compareList, wordpress }

export function normalizeChildrenList(data, type) {
  return data.map((item) => {
    return {
      ...item,
      children: item.children || [],
      parents: item.parents || [],
      kebabCase: kebabCase(item.name),
      camelCase: camelCase(item.name),
      routes: getRoutesList(item, type)
    }
  })
}

export function getRoutesList(data, type = 'stone') {
  if (type === 'color')
    return {
      stone: kebabCase(data.name) + '-gemstones',
      pair: kebabCase(data.name) + '-gemstone-pairs',
      ring: kebabCase(data.name) + '-gemstone-rings'
    }
  return {
    stone: kebabCase(data.name),
    pair: kebabCase(data.name) + '-pairs',
    ring: kebabCase(data.name) + '-rings'
  }
}

export function normalizeStonesList(data) {
  return {
    stoneTypes: data.stoneTypes.map((stone) => {
      return {
        ...stone,
        children: normalizeChildrenList(stone.children || []),
        parents: stone.parents || [],
        kebabCase: kebabCase(stone.name),
        camelCase: camelCase(stone.name),
        routes: getRoutesList(stone)
      }
    }),
    stoneColors: data.stoneColors.map((color) => {
      return {
        ...color,
        children: normalizeChildrenList(color.children || []),
        parents: color.parents || [],
        kebabCase: kebabCase(color.name),
        camelCase: camelCase(color.name),
        routes: getRoutesList(color, 'color')
      }
    })
  }
}

/**
 * Normalize prices for custom items to update prices in wishlist/compare/cart
 * @param {array.<Object>} prices
 * @returns {array.<Object>}
 */
export function normalizeCustomPrices(prices) {
  return prices.map((price, index) => {
    const p = {
      publicId:
        price.dmtoId ||
        `${price.settingId}${price.metalTypeCode}-${price.stoneId}`,
      category: 'Custom',
      finalPrice: {
        ...price
      }
    }
    delete p.finalPrice.settingId
    delete p.finalPrice.metalTypeCode
    delete p.finalPrice.stoneId
    return p
  })
}

export function normalizeShowroomSearchItems(items) {
  return {
    ...items,
    jewelry: items.jewelry.map((item) => {
      // if this is MTO item
      if (item.dataType === 2) item.id = String(item.jewelryItemId)
      return item
    })
  }
}

export function normalizeExtendPlan(plan) {
  const clonedPlan = cloneDeep(plan)
  clonedPlan.extendPlanId = plan.planId
  delete clonedPlan.planId
  return clonedPlan
}

export function normalizeBaseItem(items) {
  return items.map((item) => {
    const regEx = /(JS\d+|js\d+)([A-Za-z\d]+)-(.+)$/gm
    const result = regEx.exec(item.id)
    item.metalTypeCode = item.metalTypeCode || get(result, '[2]', null)
    item.settingId = get(result, '[1]', null)
    item.stoneId = get(result, '[3]', null)
    item.placeholder = true
    if (item.category === 'Custom') item.dataType = 2
    return item
  })
}

export function normalizeCustomDesigns(designs) {
  return designs.page.map((p) => {
    p.guid = guid()
    return p
  })
}

export function normalizeCustomDesignsFilters(filters) {
  return normalizeJewelryFiltersResponse(filters)
}

export function normalizeSharedSelectedOptions({ params }) {
  let normalized = {}
  if (params.jewelryItemId) {
    const { engraving, ringSize } = params
    normalized = {
      engraving,
      ringSize
    }
  }
  if (params.bandId || params.finishedDesign) {
    const {
      engraving,
      ringSize,
      width: ringWidth,
      sideStones: sideStoneOptions,
      finish,
      metalTypeCode
    } = params
    normalized = {
      engraving,
      ringSize,
      ringWidth,
      sideStoneOptions,
      finish: { key: finish },
      metalType: { key: metalTypeCode }
    }
  }
  if (params.settingId) {
    const { ringSize } = params
    normalized = {
      ringSize
    }
  }
  return normalized
}

export function normalizeItemsListData(itemsData, pageNumber) {
  const items = cloneDeep(itemsData)
  const { page } = cloneDeep(items)
  items.page = page.map((item, index) => {
    item.guid = guid()
    return item
  })
  items.guid = guid()
  return items
}

export function normalizeFiltersResponse(filters, filterType) {
  const filtersToOmit = []
  // const filtersToOmit = ['ruby', 'emerald'].includes(process.env.siteName)
  //   ? ['stoneTypes']
  //   : []
  const omitted = omit(omitBy(filters, isNil), filtersToOmit)
  const normalized = {}
  const dimensions = ['lengthRange', 'widthRange']
  Object.keys(omitted).forEach((filter) => {
    if (dimensions.includes(filter)) {
      normalized.dimensions = {}
      dimensions.forEach((dimension) => {
        normalized.dimensions[dimension] = filters[dimension]
      })
      return
    }
    normalized[filter] = omitted[filter]
  })
  Object.keys(normalized).forEach((key) => {
    normalized[key].filterType = filterType
    normalized[key].filterName = key
  })
  return normalized
}

export function normalizeAvailableDesigns(designs) {
  return designs.map((d) => {
    d.preview.splice(-1, 1)
    d.guid = guid()
    return d
  })
}

export function normalizeDesignItem(item) {
  return {
    category: 'Custom',
    customStone: {
      id: item.stoneId
    },
    customSetting: {
      id: item.settingId,
      metalTypeCode: item.metalTypeCode
    }
  }
}

export function normalizeJewelryFiltersResponse(filters) {
  const normalized = {}
  const filtersOrder = [
    'styles',
    'metalTypes',
    'ringSize',
    'centerStoneShapes',
    'sideStoneShapes',
    'caratRange',
    'designStyles',
    'priceRange',
    'origins',
    'enhancements',
    'clarity',
    'intensity',
    'cuttingStyles',
    'labTypes',
    'labColors',
    'categories'
  ] // order filters in menu

  filtersOrder.forEach((filter) => {
    if (!filters[filter] || !filters[filter]?.length) return
    if (!['ringSize', 'categories'].includes(filter)) {
      if (filter === 'origins') {
        normalized.stoneOrigins = filters[filter]
        return
      }
      normalized[filter] = filters[filter] // omit unnecessary filters
    }
  })
  return normalized
}

export function normalizeProfile(fields) {
  const normalized = cloneDeep(fields)
  if (!normalized.gender) normalized.gender = null
  return normalized
}

export function normalizeWeddingBandsPlainFiltersResponse(filters) {
  const normalized = {}
  // const filtersOrder = ['metalTypes', 'styles'] // order filters in menu
  Object.keys(filters).forEach((filter) => {
    if (filters[filter] && filters[filter].length) {
      normalized[filter] = filters[filter]
    }
  })
  return normalized
}

export function normalizeWeddingBandsFiltersResponse(filters) {
  const normalized = {}
  // const filtersOrder = ['metalTypes', 'stoneTypes', 'stoneShapes'] // order filters in menu
  Object.keys(filters).forEach((filter) => {
    if (filters[filter] && filters[filter].length) {
      normalized[filter] = filters[filter]
    }
  })
  return normalized
}

export function normalizeFinalOptions(options) {
  const normalized = cloneDeep(options)
  normalized.stone = normalizeStonesDetails(normalized.stone)
  normalized.pair = normalizeStonesDetails(normalized.pair)
  normalized.setting = normalizeSettingsDetails(normalized.setting)
  if (normalized.previewRequest) {
    normalized.previewRequest.category = 'Preview'
    normalized.previewRequest.subCategory = normalized.setting.category.replace(
      'Setting_',
      ''
    )
  }
  normalized.sideStoneOptions = normalizeSidestoneOptions(
    options.sideStoneOptions || []
  )
  return normalized
}

export function normalizeRingSettingsFiltersResponse(filters) {
  const keys = Object.keys(filters)
  const normalized = {}
  keys.forEach((key) => {
    if (!filters[key] || !filters[key]?.length) return
    normalized[key] = filters[key]
  })
  return normalized
}

export function normalizeEarringSettingsFiltersResponse(filters) {
  return normalizeRingSettingsFiltersResponse(filters)
}

export function normalizeBraceletSettingsFiltersResponse(filters) {
  return normalizeRingSettingsFiltersResponse(filters)
}

export function normalizePendantSettingsFiltersResponse(filters) {
  return normalizeRingSettingsFiltersResponse(filters)
}

export function normalizeNecklaceSettingsFiltersResponse(filters) {
  return normalizeRingSettingsFiltersResponse(filters)
}

export function normalizeWeddingBandsDetails(details) {
  const normalized = details
  normalized.sideStoneOptions = normalizeSidestoneOptions(
    normalized.sideStoneOptions
  )
  return normalized
}

export function normalizeStonesDetails(details) {
  if (!details) return details
  const normalized = details
  if (details.category === 'Pair') {
    normalized.cuttingStyle = normalized.stones[0].cuttingStyle
    normalized.isStar = normalized.stones[0].isStar
  }
  return normalized
}

export function normalizeSettingsDetails(details) {
  const normalized = details
  normalized.thumbnails = normalized.thumbnails || []
  normalized.prongs = normalized.prongs || []
  normalized.phoneVideos = normalized.phoneVideos || []
  return { ...normalized, selectedOptions: {} }
}

export function normalizeSidestoneOptions(options) {
  return options.map((opt) => {
    opt.weights = opt.weights.map((weight) => {
      weight.id = weight.name
      return weight
    })
    return opt
  })
  // return sortBy(normalized, ['stoneType'])
}

export function normalizeBandsItemsWishlist(item) {
  const normalized = {
    ...item,
    guid: item.guid || guid(),
    isDirty: false,
    fromServer: true,
    selectedOptions: getBandsSelectedOptions(item),
    totalPrice: item.selectedOptions.settingPrice
  }
  return normalized
}

export function normalizePlainBandsItemsWishlist(item) {
  return normalizeBandsItemsWishlist(item)
}

export function normalizeCustomItemWishlist(item) {
  const customStone = item.finished?.stone || item.finished?.pair
  const customSetting = item.finished?.setting

  return {
    id: getItemId({ customStone, customSetting }),
    guid: item.guid || guid(),
    isDirty: false,
    fromServer: true,
    category: 'Custom',
    customStone,
    customSetting,
    preview: item.finished?.previewRequest,
    designer: item.finished?.designer,
    selectedOptions: getCustomSelectedOptions(item),
    finalPrice: item.price
  }
}

export function normalizeWaxItemCart(item) {
  const normalized = normalizeCustomItemWishlist(item)
  normalized.category = 'Wax'
  return normalized
}

export function normalizeSkip(item) {
  if (!item.guid) item.guid = guid()
  item.fromServer = true
  return item
}

function getBandsSelectedOptions(item) {
  return {
    metalType: {
      key: item.metalTypeCode
    },
    finish: item.selectedOptions.finish,
    ringWidth: item.selectedOptions.width,
    ringSize: item.selectedOptions.ringSize,
    sideStoneOptions: item.selectedOptions.sideStones,
    price: item.selectedOptions.settingPrice,
    engraving: item.selectedOptions.engraving
  }
}

function getCustomSelectedOptions(item) {
  return {
    metalTypeCode: {
      key: item.finished.setting.metalTypeCode,
      value: item.finished.setting.metalName
    },
    ringSize: { key: item.selectedOptions.ringSize },
    sideStoneOptions: item.selectedOptions.sideStones,
    engraving: item.selectedOptions.engraving
  }
}

export function normalizeSelectedOptionsForURL(selectedOptions) {
  const params = {}
  if (!selectedOptions) return params
  Object.keys(selectedOptions).forEach((param) => {
    if (
      ['metalTypeCode', 'metalType'].includes(param) ||
      !selectedOptions[param]
    )
      return
    if (param === 'sideStoneOptions') {
      selectedOptions[param].forEach((sideStone, index) => {
        if (!params.grade) params.grade = []
        if (!params.stoneType) params.stoneType = []
        if (!params.weight) params.weight = []
        if (!params.position) params.position = []
        if (!params.clarity) params.clarity = []
        params.grade[index] = sideStone.grade || sideStone.gradeId
        params.stoneType[index] = sideStone.stoneType || sideStone.stoneTypeId
        params.weight[index] = sideStone.weight
        params.position[index] = sideStone.position
        params.clarity[index] = sideStone.clarity
      })
      return
    }
    params[param] = selectedOptions[param]
  })
  return params
}

export function normalizeRecommendations(recommendation) {
  return recommendation.map((r) => {
    r.item.guid = guid()
    return r
  })
}

export function normalizeDiamond(sTypes) {
  if (Array.isArray(sTypes)) {
    const uniqueTypes = sTypes.map((type) =>
      type.includes('Diamond') ? 'Diamond' : type
    )

    return [...new Set(uniqueTypes)]
  }
  return sTypes
}
