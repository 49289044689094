import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { format } from 'date-fns'
import serializeOrderData from './order'
import serializeFilter from './filter'
import {
  defaultCustomDesignsListQuery,
  defaultWeddingBandsSideStonesQuery,
  itemIdByCategory,
  defaultListItem,
  itemsWithSelectedOptions,
  bandsByCategory,
  defaultShareLinkQuery,
  defaultShareFormQuery,
  productListTypeByCategory,
  bandsByCategory as bands,
  settingsByCategory as settings
} from '~~/utils/definitions/defaults'
import { getLastDetailsPath } from '~~/utils/utils'
import { itemNameFormatter } from '~~/utils/formatters/index.js'

const dateFormatTpl = 'MM/dd/yyyy'

export { serializeOrderData, serializeFilter }

export function serializeChargeAfterWarranty(warranty) {
  return {
    name: warranty.title,
    price: warranty.price / 100,
    sku: warranty.offerPlanId,
    quantity: 1,
    productCategory: 'Warranty'
  }
}

export function serializeChargeAfter(store) {
  const cartState = store.state.cart
  const getters = store.getters

  const shippingAddress = {
    line1: cartState.addressForm.shippingStreet1,
    line2: cartState.addressForm.shippingStreet2,
    city: cartState.addressForm.shippingCity,
    zipCode: cartState.addressForm.shippingZipCode,
    state: cartState.addressForm.shippingState
  }

  const billingAddress = cartState.addressForm.shipToBilling
    ? shippingAddress
    : {
        line1: cartState.addressForm.billingStreet1,
        line2: cartState.addressForm.billingStreet2,
        city: cartState.addressForm.billingCity,
        zipCode: cartState.addressForm.billingZipCode,
        state: cartState.addressForm.billingState
      }

  const items = []
  getters['cart/items'].forEach((item) => {
    items.push(serializeChargeAfterItem(item))
    if (item.extendPlan) {
      items.push(serializeChargeAfterWarranty(item.extendPlan))
    }
  })

  return {
    consumerDetails: {
      firstName: cartState.addressForm.shippingFirstName,
      lastName: cartState.addressForm.shippingLastName,
      email: cartState.addressForm.email,
      mobilePhoneNumber: cartState.addressForm.phone,
      merchantConsumerId: 'merchant-internal-consumer-id',
      shippingAddress,
      billingAddress
    },

    // Cart information
    cartDetails: {
      items,
      // discounts: items.map((item) => item.discounts).flat(),

      taxAmount: getters['cart/getTax'],
      shippingAmount: getters['cart/shipping'],
      totalAmount: getters['cart/getTotal']
    }
  }
}

function serializeChargeAfterItem(item) {
  const price = item.discountPrice || item.price || item.finalPrice.totalPrice

  const serializedItem = {
    name: itemNameFormatter(item),
    price,
    sku: item.id,
    quantity: 1,
    discounts: [],
    productCategory: item.category
  }

  const saleName = item.saleName || item.customStone?.saleName || 'No sale' // for case when saleName is not available

  if (item.category === 'Custom') {
    const discount = item.finalPrice.discountAmt

    if (discount) {
      serializedItem.discounts.push({
        name: saleName,
        amount: discount
      })
    }
  } else {
    const discount = item.price - (item.discountPrice || item.price) // for case when discountPrice is not available

    if (discount) {
      serializedItem.discounts.push({
        name: saleName,
        amount: discount
      })
    }
  }

  return serializedItem
}

export function serializeContactsForm({ form, rootGetters }) {
  const data = cloneDeep(form)

  data.pageLink = getLastDetailsPath(rootGetters['navigation/lastVisitedPage'])

  data.salesPerson = data.salesPerson.id

  return data
}

export function serializeAskAboutForm(form, item) {
  const data = cloneDeep(form)

  data.salesPerson = data.salesPerson.id
  data.selectedOptions = item?.selectedOptions || {}

  // fix for wedding bands
  if (data.selectedOptions.metalType) {
    data.selectedOptions.metalTypeCode = data.selectedOptions.metalType
    delete data.selectedOptions.metalType
  }

  return data
}

export function serializeShowroomForm(form) {
  const f = cloneDeep(form)
  f.salesPerson = f.salesPerson.id
  return f
}

export function serializeBaseItems(items, listType) {
  return items.map((item) => {
    item.listType = listType
    return {
      guid: item.guid,
      listType: listType.replace('favorites', 'favorite')
    }
  })
}

export function serializeCustomDesignsFilters(rootState, settingId, param) {
  const selectedFilters = rootState.customItem.selectedCustomDesignsFilters
  const query = cloneDeep(defaultCustomDesignsListQuery)
  query.query[param] = settingId
  if (param === 'stoneId') {
    query.query.category = ['Ring', 'Necklace', 'Pendant', 'Earring']
  } else {
    query.query.category = [
      rootState.productDetails.productDetails.category.replace('Setting_', '')
    ]
  }
  if (selectedFilters.stoneOrigins)
    query.query.origins = selectedFilters.stoneOrigins
  if (selectedFilters.designStyles)
    query.query.styles = selectedFilters.designStyles
  if (selectedFilters.metalTypes)
    query.query.metalType = selectedFilters.metalTypes
  if (selectedFilters.caratRange) {
    const min = selectedFilters.caratRange[0]
    const max = selectedFilters.caratRange[1]
    query.query.weight = { min, max }
  }
  if (selectedFilters.priceRange) {
    const min = selectedFilters.priceRange[0]
    const max = selectedFilters.priceRange[1]
    query.query.price = { min, max }
  }
  if (selectedFilters.enhancements)
    query.query.enhancements = selectedFilters.enhancements
  return query
}

export function serializeShareLink({ item }) {
  let selectedOptions = {}
  if (itemsWithSelectedOptions.includes(item.category)) {
    const {
      engraving,
      finish,
      metalType,
      metalTypeCode,
      ringSize,
      ringWidth: width,
      sideStoneOptions: sideStones
    } = item.selectedOptions
    selectedOptions = {
      engraving,
      finish: finish ? finish.key : null,
      metalTypeCode:
        get(metalType, 'key', null) || get(metalTypeCode, 'key', null),
      ringSize: ringSize.key,
      sideStones: serializeSideStones(sideStones)
    }
    if (width) selectedOptions.width = width.key
  }
  const serialized = { ...defaultShareLinkQuery, ...selectedOptions }
  if (item.category === 'Stone') serialized.stoneId = item.id
  if (item.category === 'Pair') serialized.stoneSetId = item.id
  if (item.category.includes('Setting_')) {
    serialized.settingId = item.id
    serialized.metalTypeCode = item.metalTypeCode
  }
  if (
    ['Ring', 'Earring', 'Pendant', 'Necklace', 'Bracelet'].includes(
      item.category
    )
  ) {
    serialized.jewelryItemId = item.id
    serialized.metalTypeCode = item.metalTypeCode
  }
  if (bandsByCategory.includes(item.category)) {
    serialized.bandId = item.id
  }
  if (item.category === 'Custom') {
    serialized.finishedDesign = true
    serialized.metalTypeCode = item.customSetting.metalTypeCode
    serialized.settingId = item.customSetting.id
    if (item.customStone.category === 'Stone')
      serialized.stoneId = item.customStone.id
    if (item.customStone.category === 'Pair')
      serialized.stoneSetId = item.customStone.id
  }
  return serialized
}

export function serializePageQuery(pageQuery) {
  if (isEmpty(pageQuery)) return
  return {
    pageNumber: pageQuery.page
  }
}

export function serializeShareForm({
  params: { form, route, pageLink, item },
  options
}) {
  const serialized = { ...defaultShareFormQuery, ...form }
  serialized.pageLink = pageLink
  serialized.recipientsEmail = [form.recipient1, form.recipient2].filter(
    (email) => email
  )
  delete serialized.recipient1
  delete serialized.recipient2
  serialized.options = options
  return serialized
}

export function serializeRequestPreview({ form, customItem }) {
  return {
    stoneId: customItem.customStone.id,
    settingId: customItem.customSetting.id,
    metalTypeCode: get(customItem, 'customSetting.metalTypeCode', ''),
    'g-recaptcha-response': form['g-recaptcha-response'],
    customerName: form.customerName,
    email: form.email,
    country: form.country.value,
    phone: form.phone,
    pageLink: form.pageLink,
    occasion: form.occasion.value,
    timeframe: form.timeframe.value,
    salesPersonId: form.teamMember.id,
    deliveryDate: form.deliveryDate
      ? format(new Date(form.deliveryDate), dateFormatTpl)
      : '',
    comments: form.comments,
    size: get(customItem, 'selectedOptions.ringSize.key', 0),
    vedicSetting: !!get(customItem, 'selectedOptions.vedicSetting', false),
    prongTypeId: get(customItem, 'selectedOptions.prong.id', null),
    sideStones: serializeSideStones(
      get(customItem, 'selectedOptions.sideStoneOptions', [])
    )
  }
}

export function serializeProfile(fields) {
  const normalized = cloneDeep(fields)
  normalized.birthday = {
    day: normalized.birthdayDay || null,
    month: normalized.birthdayMonth?.key || null
  }
  normalized.relationship = normalized.relationship.key
  delete normalized.birthdayDay
  delete normalized.birthdayMonth
  return normalized
}

export function serializePassword(fields) {
  const normalized = JSON.parse(JSON.stringify(fields))
  const keys = Object.keys(normalized)
  keys.forEach((key) => {
    normalized[key] = !normalized[key] ? '' : normalized[key]
  })
  return normalized
}

export function serializeRemoveListItems(items) {
  return items.map((item) => {
    const itemId = getItemId(item)

    return {
      ...defaultListItem,
      ...itemId,
      guid: item.guid || null
    }
  })
}

function getItemId(item) {
  if (item.category === 'Wax')
    return {
      isWax: true,
      [itemIdByCategory[item.customStone.category]]: item.customStone.id,
      [itemIdByCategory[item.customSetting.category]]: item.customSetting.id,
      metalTypeCode: item.customSetting.metalTypeCode
    }
  if (item.category === 'Custom')
    return {
      finishedDesign: true,
      [itemIdByCategory[item.customStone.category]]: item.customStone.id,
      [itemIdByCategory[item.customSetting.category]]: item.customSetting.id,
      metalTypeCode: item.customSetting.metalTypeCode
    }
  if (item.dataType === 2) {
    const data = {
      finishedDesign: true,
      settingId: item.settingPubId || null,
      metalTypeCode: item.metalTypeCode
    }
    if (item.category === 'Earring') data.stoneSetId = item.stonePubId
    if (item.category !== 'Earring') data.stoneId = item.stonePubId
    return data
  }
  return {
    [itemIdByCategory[item.category]]: item.id,
    metalTypeCode: item.metalTypeCode || null
  }
}

export function serializeListItems(wishlistItems) {
  return wishlistItems.map((item) => {
    if (settings.includes(item.category)) {
      return {
        ...defaultListItem,
        guid: item.guid || null,
        [itemIdByCategory[item.category]]: item.id,
        metalTypeCode: item.metalTypeCode,
        ringSize: get(item, 'selectedOptions.ringSize.key', null)
      }
    }
    if (bands.includes(item.category)) {
      return {
        ...defaultListItem,
        guid: item.guid || null,
        [itemIdByCategory[item.category]]: item.id,
        metalTypeCode:
          get(item, 'selectedOptions.metalType.key', null) ||
          item.metalTypeCode,
        ringSize: get(item, 'selectedOptions.ringSize.key', null),
        width: get(item, 'selectedOptions.ringWidth.key', null),
        finish: get(item, 'selectedOptions.finish.key', null),
        sideStones: serializeSideStones(
          get(item, 'selectedOptions.sideStoneOptions', [])
        ),
        engraving: get(item, 'selectedOptions.engraving', null),
        settingPrice: get(item, 'totalPrice', null)
      }
    }
    if (['Custom', 'Wax'].includes(item.category)) {
      const isWax = item.category === 'Wax'
      const query = {
        ...defaultListItem,
        guid: item.guid || null,
        [itemIdByCategory[item.customStone.category]]: item.customStone.id,
        [itemIdByCategory[item.customSetting.category]]: item.customSetting.id,
        engraving: item.selectedOptions.engraving,
        metalTypeCode: item.selectedOptions.metalTypeCode.key,
        ringSize: get(item, 'selectedOptions.ringSize.key', null),
        width: item.customStone.width,
        settingPrice: item.finalPrice.settingPrice,
        sideStones: serializeSideStones(item.selectedOptions.sideStoneOptions),
        finishedDesign: true,
        isWax
      }

      if (item.dataType === 3) query.dmtoId = item.preview.itemRealId

      return query
    }
    return {
      // stones and jewelry
      ...defaultListItem,
      guid: item.guid || null,
      [itemIdByCategory[item.category]]: item.id,
      ...(item.category === 'Ring'
        ? {
            ringSize: get(item, 'selectedOptions.ringSize.key', null),
            engraving: get(item, 'selectedOptions.engraving', '')
          }
        : {})
    }
  })
}

export function serializeWeddingBandsSelectedOptions({ item }) {
  return {
    id: item.id,
    type: productListTypeByCategory[item.category],
    metalTypeCode: item.metalTypeCode,
    size: item.selectedOptions.ringSize,
    width: item.selectedOptions.ringWidth,
    sideStones: serializeSideStones(item.selectedOptions.sideStoneOptions)
  }
}

export function serializeWeddingBands(productDetails) {
  return {
    id: productDetails.id,
    engraving: productDetails.selectedOptions.engraving,
    type: productListTypeByCategory[productDetails.category],
    metalTypeCode: productDetails.selectedOptions.metalType.key,
    size: productDetails.selectedOptions.ringSize.key,
    width: productDetails.selectedOptions.ringWidth.key,
    finish: productDetails.selectedOptions.finish.key,
    sideStones: serializeSideStones(
      productDetails.selectedOptions.sideStoneOptions
    )
  }
}

export function serializeDmtoItem(item) {
  return {
    id: item.id,
    metalTypeCode: item.metalTypeCode,
    size: item.ringSize
  }
}

export function serializeWeddingBandsDetails({
  id,
  metalTypeCode,
  size,
  width,
  sideStones
}) {
  const query = {
    ...defaultWeddingBandsSideStonesQuery,
    id,
    metalTypeCode,
    size,
    width,
    sideStones
  }
  if (!sideStones.length) delete query.sideStones
  return query
}

export function serializeCustomItem(item) {
  const { customStone, customSetting, selectedOptions, preferableRingSizeId } =
    item

  const ringSize = get(selectedOptions, 'ringSize.key', null)
  const result = {
    id: customSetting.id?.toUpperCase(),
    stoneId: customStone.id?.toUpperCase(),
    metalTypeCode: customSetting.metalTypeCode.toUpperCase()
  }

  if (preferableRingSizeId) result.size = preferableRingSizeId
  if (ringSize) result.size = ringSize
  if (item.dataType === 3) result.id = item.preview?.itemRealId

  return result
}

export function getCustomItem({
  itemId,
  dataType,
  guid,
  customStone,
  customSetting,
  finalPrice,
  sideStones,
  selectedOptions,
  designer,
  preview,
  category,
  itemTypeId
}) {
  const item = {
    id: itemId || '',
    guid: guid || '',
    category,
    dataType: dataType || 1,
    designer,
    customStone,
    customSetting,
    selectedOptions,
    sideStoneOptions: sideStones,
    finalPrice,
    preview,
    itemTypeId
  }
  const previewRequestId = get(preview, 'itemRealId', null)

  if (previewRequestId) {
    if (!isEmpty(item.customStone))
      item.customStone.previewRequestId = previewRequestId
    if (!isEmpty(item.customSetting))
      item.customSetting.previewRequestId = previewRequestId
  }

  if (dataType === 3) item.id = preview.itemRealId

  // if (dataType === 3) {
  //   item.id = preview.itemRealId
  //   // item.category = customSetting.category.replace('Setting_', '')
  // }

  return item
}

export function getCustomItemWax(params) {
  const customItem = getCustomItem(params)
  customItem.category = 'Wax'
  // customItem.id = `Setting ID: ${customItem.customSetting.id} / Stone ID: ${customItem.customStone.id}`
  return customItem
}

function serializeSideStones(sideStones) {
  if (!sideStones) return []
  return sideStones.map((stone) => {
    return {
      position: stone.position,
      gradeId: get(stone, 'grade.id', stone.gradeId),
      stoneTypeId: get(stone, 'stoneType.id', stone.stoneTypeId),
      weight: get(stone, 'weight.id', stone.weight),
      clarityId: get(stone, 'clarity.id', stone.clarityId)
    }
  })
}

export function serializeCustomItemPrice(item) {
  const { customStone, customSetting, selectedOptions } = item
  const serialized = {
    id: customSetting.id,
    stoneId: customStone.id,
    metalTypeCode: customSetting.metalTypeCode,
    sideStones: serializeSideStones(selectedOptions.sideStoneOptions || [])
  }
  if (selectedOptions.ringSize) {
    serialized.size = selectedOptions.ringSize.key
  }

  // if (item.dataType === 3) serialized.id = item.preview?.itemRealId
  // if (item.dataType === 3) serialized.dmtoId = item.preview?.itemRealId

  return serialized
}

export function serializeCustomDmtoItemPrice({
  customStone,
  customSetting,
  preview,
  selectedOptions
}) {
  const serialized = {
    id: preview.itemRealId,
    stoneId: customStone.id,
    metalTypeCode: customSetting.metalTypeCode,
    sideStones: serializeSideStones(selectedOptions.sideStoneOptions || [])
  }
  if (selectedOptions.ringSize) {
    serialized.size = selectedOptions.ringSize.key
  }
  return serialized
}
