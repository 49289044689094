import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { normalizeExtendPlan } from '~~/utils/normalizers'
import { bandsByCategory } from '~~/utils/definitions/defaults'

export default function (state, getters) {
  const cartState = cloneDeep(state)
  const { paymentForm, addressForm, companyAddress, allCreditCards } = cartState

  if (getters.pickUpDeliverySelected) Object.assign(addressForm, companyAddress)

  const { lineItemType, countries } = cartState.metaData

  const lineItems = []

  if (addressForm.shipToBilling) {
    const fields = [
      'FirstName',
      'LastName',
      'Street1',
      'Street2',
      'City',
      'ZipCode',
      'State',
      'CountryId'
    ]
    fields.forEach((field) => {
      addressForm[`billing${field}`] = addressForm[`shipping${field}`]
    })
  }

  getters.items.forEach((item) => {
    if (addressForm.shippingCountryId === 'US' && item.extendPlan)
      lineItems.push(getExtendLineItem(item, lineItemType))
    if (item.customSetting) {
      lineItems.push(getSettingLineItem(item, lineItemType))
      if (item.category !== 'Wax')
        lineItems.push(getLineItem(item.customStone, lineItemType))
      return
    }
    if (['Wedding Band', 'Plain Band'].includes(item.category)) {
      lineItems.push(getBandLineItem(item, lineItemType))
      return
    }
    lineItems.push(getLineItem(item, lineItemType))
  })

  const shippingCountry = countries.find(
    (c) => c.key === addressForm.shippingCountryId
  ).value

  const billingCountry = addressForm.shipToBilling
    ? shippingCountry
    : countries.find((c) => c.key === addressForm.billingCountryId).value

  const removeCardTypeView = (cards) => {
    return cards.map((card) => {
      const { cardTypeView, ...cardWithoutTypeView } = card
      return cardWithoutTypeView
    })
  }

  const creditCards = removeCardTypeView(allCreditCards)

  if (!getters.showDocsOptions) paymentForm.docType = 0 // printed documents for expensive orders by default

  return omit(
    {
      salesPersonId: addressForm.teamMember.id,
      shippingCountry,
      billingCountry,
      firstName: addressForm.shippingFirstName,
      lastName: addressForm.shippingLastName,
      ...addressForm,
      ...paymentForm,
      creditCards,
      lineItems
    },
    ['delivery', 'teamMember']
  )
}

function getExtendLineItem(item) {
  let extendLinkedItemId = item.id
  if (bandsByCategory.includes(item.category))
    extendLinkedItemId += item.metalTypeCode

  return {
    ...normalizeExtendPlan(item.extendPlan),
    itemGuid: item.guid || null,
    itemTypeId: 21,
    itemId: 'EXTEND',
    referenceId: item.extendReferenceId || item.finalPrice?.extendReferenceId,
    extendLinkedItemId
  }
}

function getBandLineItem(item, lineItemType) {
  return {
    itemTypeId: lineItemType.find(
      (i) => i.value === categoryHandler(item.category)
    ).key,
    itemId: item.id,
    finishStyleId: item.selectedOptions.finish.key,
    metalTypeCode: item.metalTypeCode,
    ringSize: item.size,
    ringWidth: item.width,
    engraving: item.selectedOptions.engraving
      ? item.selectedOptions.engraving
      : null,
    sideStones: getSidestones(item)
  }
}

function getLineItem(item, lineItemType) {
  const hasOptions = !!item.selectedOptions
  const ringSize = hasOptions
    ? get(item, 'selectedOptions.ringSize.key', null)
    : item.ringSize || null

  const normalizedItem = {
    itemId: item.id,
    itemTypeId: lineItemType.find(
      (i) => i.value === categoryHandler(item.category)
    ).key,
    metalId: item.metal && item.metal.key ? item.metal.key : null,
    ringSize,
    engraving:
      item.selectedOptions && item.selectedOptions.engraving
        ? item.selectedOptions.engraving
        : null
  }

  if (item.previewRequestId)
    normalizedItem.previewRequestId = item.previewRequestId

  return normalizedItem
}

function categoryHandler(category) {
  switch (category) {
    case 'Ring':
    case 'Earring':
    case 'Pendant':
    case 'Necklace':
    case 'Bracelet':
    case 'Bangle':
    case 'Brooch':
    case 'Cufflink':
      return 'Jewelry'
    case 'Wedding Band':
    case 'Plain Band':
      return 'Band'
    case 'Setting_Ring':
    case 'Setting_Earring':
    case 'Setting_Bracelet':
    case 'Setting_Necklace':
    case 'Setting_Pendant':
      return 'Setting'
    default:
      return category
  }
}

function getSettingLineItem(item, lineItemType) {
  const setting = item.customSetting
  const stone = item.customStone
  const category = item.category !== 'Custom' ? item.category : setting.category

  const previewRequestId = get(item, 'preview.itemRealId', null)

  const normalizedItem = {
    itemTypeId: lineItemType.find((i) => i.value === categoryHandler(category))
      .key,
    itemId: setting.id,
    metalTypeCode: setting.metalTypeCode,
    ringSize: get(item, 'selectedOptions.ringSize.key', null),
    engraving: get(item, 'selectedOptions.engraving', null),
    vedicSetting: get(item, 'selectedOptions.vedicSetting', false),
    prongTypeId: get(item, 'selectedOptions.prong.id', null),
    centerStoneId: stone.id,
    sideStones: getSidestones(item)
  }

  if (item.dataType === 3) {
    const itemTypeId = lineItemType.find((i) => i.value === 'DMTO')
    if (itemTypeId) normalizedItem.itemTypeId = itemTypeId.key
    normalizedItem.itemId = item.preview.itemRealId
  }
  if (previewRequestId) normalizedItem.previewRequestId = previewRequestId

  return normalizedItem
}

function getSidestones(item) {
  const selected = item.selectedOptions.sideStoneOptions

  if (!selected || !selected.length) return []

  const options = item.sideStoneOptions

  return selected.map((ss, i) => {
    const grade = options[i].grades.find((g) => g.id === ss.grade.id)
    const stoneType = options[i].stoneTypes.find(
      (g) => g.id === ss.stoneType.id
    )
    const clarity = options[i].clarities.find((c) => c.id === ss.clarity.id)
    const weight = ss.weight
      ? ss.weight.name
      : options[i].weights.find((g) => g.id === ss.weight.id).name
    const shape = options[i].shape
    // const stoneType = options[i].stoneType
    const color = options[i].color
    const quantity = options[i].quantity

    return {
      gradeId: grade.id,
      stoneTypeId: stoneType.id,
      gradeName: grade.name,
      weight: Number(weight),
      clarityId: clarity.id,
      clarityName: clarity.name,
      shape,
      color,
      quantity
    }
  })
}
